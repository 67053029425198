import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useBarcodeScanner from "../../hooks/useBarcodeScanner";
import { InsufficientPermissionError } from "../../hooks/useCamera";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";
import logger from "../../utils/logger";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import useSyncedState from "../../hooks/useSyncedState";
import WidgetContainer from "../WidgetContainer";

export type WidgetBarcodeProperties = {
  required: boolean;
  label_text: string;
};

const WidgetBarcode: Widget<WidgetBarcodeProperties, WidgetResult<string>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();
  const [localState, setLocalState] = useSyncedState(fieldState.value.rawValue);
  const [showPermissionsModal, setPermissionsModal] = useState(false);
  const { startScan, isScanSupported, isScannerInstalling } = useBarcodeScanner();

  const startScanning = async (): Promise<void> => {
    try {
      const result = await startScan();
      setFieldState(result);
    } catch (e) {
      if (e instanceof InsufficientPermissionError) {
        setPermissionsModal(true);
        return;
      }
      throw e;
    }
  };

  return (
    <WidgetContainer fieldState={fieldState} name="BARCODE_FIELD">
      <TextInput
        name={fieldState.uniqueFieldId}
        value={localState}
        errorMessage={fieldState.error}
        disabled={readOnly}
        onChange={(e) => setLocalState(e.target.value)}
        onBlur={() => setFieldState(localState)}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        rightBtn={{
          "aria-label": t("OPEN_BARCODE_SCANNER"),
          icon: "QrcodeIcon",
          size: "md",
          onClick: () => {
            startScanning().catch((e) => logger.error("Barcode scanner could not start", e));
          },
          disabled: !isScanSupported || readOnly,
          loading: isScannerInstalling,
        }}
      />
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setPermissionsModal(false)} />
      )}
    </WidgetContainer>
  );
};

WidgetBarcode.defaultValue = (_properties, defaultMeta: any): WidgetResult<string> => ({
  type: "string",
  meta: {
    widget: "barcode",
    ...defaultMeta,
  },
});

WidgetBarcode.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetBarcode;
