export const templateRegex = /\${([^}]*)}/g;

export const traversePath = (path: string, data: any): any => {
  const components = path.split(".");
  let target = data;
  for (const element of components) {
    target = target[element];
    if (target == null) {
      return null;
    }
  }
  return target;
};
