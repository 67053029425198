import { FC, PropsWithChildren } from "react";

type ComposeProps = {
  components: FC<PropsWithChildren<any>>[];
};

const Compose: FC<PropsWithChildren<ComposeProps>> = ({ children, components }) => (
  <>
    {components.reduceRight(
      (acc, Comp) => (
        <Comp>{acc}</Comp>
      ),
      children,
    )}
  </>
);

export default Compose;
