import { FC, RefObject, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchResult } from "leaflet-geosearch/lib/providers/provider";
import { Map } from "leaflet";
import { useDebounceValue } from "usehooks-ts";
import useFocus from "../../../hooks/useFocus";
import { formatAddress, LocationProvider, OpenCageResult, openCageToLocationResult } from "../../../utils/locationUtil";
import EmptyContent from "../../EmptyContent";
import logger from "../../../utils/logger";
import { LocationResult } from "../../../types/Widget";
import { Text } from "../../../storybook/components/Text/Text";
import { Spinner } from "../../../storybook/components/Spinner/Spinner";
import { Icon } from "../../../storybook/components/Icon/Icon";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";
import { SearchField } from "../../../storybook/components/SearchField/SearchField";

type LocationProps = {
  open: boolean;
  onClose: () => void;
  onSelect?: (val: LocationResult) => void;
  mapRef?: RefObject<Map>;
  query: string;
  setQuery: (val: string) => void;
};

const SEARCH_DEBOUNCE = 300;

const SearchDrawer: FC<LocationProps> = ({ open, onClose, onSelect, mapRef, query, setQuery }) => {
  const { t } = useTranslation();
  const [debouncedQuery] = useDebounceValue<string>(query, SEARCH_DEBOUNCE);
  const provider = useMemo(() => new LocationProvider({ params: { address_only: 1 } }), []);
  const [results, setResults] = useState<SearchResult<OpenCageResult>[]>([]);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [searchFieldRef] = useFocus();

  useEffect(() => {
    if (!debouncedQuery || !open) {
      setResults([]);
      return;
    }
    setFetching(true);
    searchLocation(provider, debouncedQuery, setResults)
      .catch((e) => {
        logger.warn("Not able to retrieve location from OpenCage", e);
        setResults([]);
      })
      .finally(() => setFetching(false));
  }, [debouncedQuery, provider, open]);

  const drawerContent = useMemo(() => {
    if (isFetching) {
      return <Spinner className="m-auto mt-8" />;
    }
    if (results.length === 0 && debouncedQuery) {
      return <EmptyContent title={t("NO_RESULTS")} description={t("NO_RESULTS_DESCRIPTION")} />;
    }
    return (
      <div className="divide-y px-5 py-2">
        {results.map((result) => {
          const label = formatAddress(result);
          const onClick = (): void => {
            const locationResult = openCageToLocationResult(result);
            mapRef?.current?.flyTo({ lat: result.y, lng: result.x }, 18, { animate: false });
            onSelect && onSelect(locationResult);
            setQuery(result.label);
            onClose && onClose();
          };
          return (
            <div
              key={`${result.label}-${result.x}-${result.y}`}
              role="button"
              tabIndex={0}
              className="flex flex-row items-center hover:bg-gray-100"
              onKeyDown={(e) => {
                if (!!onClick && e.key === "Enter" && e.target === e.currentTarget) {
                  onClick();
                }
              }}
              onClick={onClick}
            >
              <Icon name="LocationMarkerIcon" type="outline" className="ml-0.5 mr-2.5 text-brand-500" />
              <div className="pb-3.5 pt-2.5">
                <Text size="lg" weight="semibold">
                  {label[0]}
                </Text>
                <Text color="medium">{label.length > 1 ? label.slice(1).join(", ") : undefined}</Text>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [debouncedQuery, isFetching, results]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Drawer
      header={{
        kind: "simple",
        title: t("SEARCH"),
        button: { kind: "icon", icon: "XIcon", onClick: onClose },
        content: (
          <div className="px-5 pb-6">
            <SearchField
              placeholder={t("SEARCH_PLACEHOLDER")}
              focusableRef={searchFieldRef}
              value={query}
              onChange={(value) => setQuery(value)}
            />
          </div>
        ),
      }}
      onClose={onClose}
      open={open}
      contentPadding={false}
      initialFocus={searchFieldRef}
    >
      {drawerContent}
    </Drawer>
  );
};

const searchLocation = async (
  provider: LocationProvider,
  query: string,
  setResults: (value: SearchResult<OpenCageResult>[]) => void,
): Promise<void> => {
  const results = await provider.search({ query });
  setResults(results as SearchResult[]);
};

export default SearchDrawer;
