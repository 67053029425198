import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useMemo, useState } from "react";
import { Geolocation, PermissionStatus } from "@capacitor/geolocation";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { noopAsync } from "../utils/noop";

export const PermissionsProvider: FC<PropsWithChildren<object>> = ({ children }) => {
  const [locationPermissions, setLocationPermissions] = useState<PermissionStatus>();

  useAsyncEffect(
    async () => {
      if (!locationPermissions) {
        const status = await Geolocation.checkPermissions();
        setLocationPermissions(status);
      }
    },
    noopAsync,
    [locationPermissions],
  );

  const providerValue = useMemo(() => ({ locationPermissions, setLocationPermissions }), [locationPermissions]);

  return <PermissionsContext.Provider children={children} value={providerValue} />;
};

type PermissionsState = {
  locationPermissions?: PermissionStatus;
  setLocationPermissions: Dispatch<SetStateAction<PermissionStatus | undefined>>;
};

export const PermissionsContext = createContext<PermissionsState>({} as PermissionsState);
