import { isEmpty, isNil } from "lodash-es";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { ActiveEntry } from "../types/Widget";
import useSubmissionStore from "./useSubmissionStore";
import { sortEntries } from "../utils/entryUtil";
import { FieldState } from "../types/SubmissionState";
import { WidgetProperties } from "../types/FormVersion";
import { WidgetResult } from "../types/Field";
import { SubformEntry } from "../components/widgets/WidgetSubform";

type UseActiveEntryResult<META> = [
  entries: SubformEntry<META>[],
  activeEntry: ActiveEntry | undefined,
  setActiveEntry: Dispatch<SetStateAction<ActiveEntry | undefined>>,
];

const useEntries = <META>(
  fieldState: FieldState<WidgetProperties, WidgetResult<unknown>>,
): UseActiveEntryResult<META> => {
  const entries = useMemo<SubformEntry<META>[]>(
    () => (fieldState.value.entries ?? []).filter((x) => !x.deleted).sort(sortEntries) ?? [],
    [fieldState.value.entries],
  );
  const [activeEntry, setActiveEntry] = useState<ActiveEntry>();
  const activeEntryIsHumanEdited = useSubmissionStore((store) =>
    isNil(activeEntry)
      ? false
      : !isEmpty(
          store.formState.fields.find((f) => f.value.meta.entryId === activeEntry?.id && f.value.meta.humanEdited),
        ),
  );

  useEffect(() => {
    if (activeEntry?.id && !activeEntry.isHumanEdited && activeEntryIsHumanEdited) {
      setActiveEntry({ ...activeEntry, isHumanEdited: true });
    }
  }, [activeEntry, activeEntryIsHumanEdited]);

  return [entries, activeEntry, setActiveEntry];
};

export default useEntries;
