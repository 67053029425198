import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { useMoreAppClient } from "../context/MoreAppContext";
import { seconds } from "../utils/timeUtil";
import useAuth from "./useAuth";

export type Customer = {
  customerId: number;
  name: string;
};

const useCustomers = (): UseQueryResult<Customer[], Error> => {
  const { authorization, setCustomers } = useAuth();
  const client = useMoreAppClient();
  return useQuery<Customer[], Error>({
    queryKey: ["customers"],
    queryFn: async () => {
      const { data } = await client!.get("/api/v1.0/customers?filter=user");
      return data;
    },
    onSuccess: (data) => setCustomers(data),
    staleTime: seconds(15),
    enabled: !!authorization?.accessToken,
    cacheTime: Infinity,
  });
};

export default useCustomers;
