import React, { FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { Network } from "@capacitor/network";

export const OnlineStatusProvider: FC<PropsWithChildren<object>> = ({ children }) => {
  const [isOnline, setOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    Network.getStatus().then((status) => setOnline(status.connected));
    const listener = Network.addListener("networkStatusChange", (status) => {
      setOnline(status.connected);
    });

    return (): void => {
      listener.then((it) => it.remove());
    };
  }, []);

  return <OnlineStatusContext.Provider value={{ isOnline }}>{children}</OnlineStatusContext.Provider>;
};

const useOnlineStatus = (): OnlineState => useContext(OnlineStatusContext);

export type OnlineState = {
  isOnline: boolean;
};

const OnlineStatusContext = React.createContext({} as OnlineState);

export default useOnlineStatus;
