import { FC } from "react";
import { IconName } from "../Icon/Icon";
import { Button, GenericButtonProps } from "../Button/Button";

export type IconButtonProps = {
  icon: IconName;
  iconType?: "solid" | "outline";
  iconColor?: "default" | "destructive" | "medium";
  size?: "lg" | "md";
} & GenericButtonProps;

export const IconButton: FC<IconButtonProps> = (props) => <Button iconType="solid" {...props} />;
