import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import ResourceImage from "../ResourceImage";
import { Radio, RadioOption } from "../../storybook/components/Radio/Radio";
import WidgetContainer from "../WidgetContainer";

type RadioOptionProperties = {
  id: string;
  name: string;
  imgResource?: string;
};

export type WidgetRadioProperties = {
  label_text: string;
  radio_options: RadioOptionProperties[];
  default_value?: string;
  required: boolean;
  use_images_as_labels?: boolean;
  vertical_alignment?: boolean;
};

const WidgetRadio: Widget<WidgetRadioProperties, WidgetResult<string>> = ({ fieldState, setFieldState, readOnly }) => {
  const { t } = useTranslation();

  const options: RadioOption[] = useMemo(
    () =>
      fieldState.properties.use_images_as_labels
        ? fieldState.properties.radio_options.map(({ imgResource, id }) => ({
            label: {
              kind: "image",
              image: imgResource ? (
                <ResourceImage
                  className="size-fit max-h-full min-h-8 object-contain mix-blend-darken"
                  resourceId={imgResource}
                />
              ) : (
                <span />
              ),
            },
            value: id,
          }))
        : fieldState.properties.radio_options.map(({ name, id }) => ({
            label: { kind: "text", value: name },
            value: id,
          })),
    [fieldState.properties.radio_options, fieldState.properties.use_images_as_labels],
  );

  return (
    <WidgetContainer fieldState={fieldState} name="RADIO_FIELD">
      <Radio
        name={fieldState.uniqueFieldId}
        label={fieldState.properties.label_text}
        value={fieldState.value.rawValue}
        errorMessage={fieldState.error}
        disabled={readOnly}
        options={options}
        layout={fieldState.properties.vertical_alignment ? "vertical" : "horizontal"}
        required={fieldState.properties.required}
        onChange={(e) => setFieldState(e.target.value)}
        clearLabel={t("CLEAR")}
        onClear={() => setFieldState(undefined)}
      />
    </WidgetContainer>
  );
};

WidgetRadio.defaultValue = (properties, defaultMeta): WidgetResult<string> => ({
  type: "string",
  rawValue: properties.default_value,
  meta: {
    widget: "radio",
    ...defaultMeta,
  },
});

WidgetRadio.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetRadio;
