import { totalFieldsRequest, totalSubmissionRequest } from "./pullProgressUtil";
import { GraphQLClientRequestHeaders } from "graphql-request/build/cjs/types";

export type Progress = {
  total: number;
  current: number;
};

export class PullProgress {
  private fields: Progress;

  private submissions: Progress;

  constructor() {
    this.fields = { total: 0, current: 0 };
    this.submissions = { total: 0, current: 0 };
  }

  addFieldCount(count: number): void {
    this.fields.current += count;
  }

  addSubmissionCount(count: number): void {
    this.submissions.current += count;
  }

  reset(): void {
    this.fields = { total: 0, current: 0 };
    this.submissions = { total: 0, current: 0 };
  }

  isPulling(): boolean {
    return this.submissions.total !== 0 || this.fields.total !== 0;
  }

  totalPercent(): number {
    if (this.submissions.total + this.fields.total === 0) {
      return 100; // Because there is no data to retrieve, everything is retrieved.
    }
    const round = Math.round(
      ((this.submissions.current + this.fields.current) / (this.submissions.total + this.fields.total)) * 100,
    );
    // Sometimes data is lost when pulling, and it will be re-pulled and added to the current counter twice
    // prevents the visual % from going over 99
    if (round > 99) {
      return 99;
    }
    return round;
  }

  totalProgress(): string {
    return `${this.submissions.current + this.fields.current}/${this.submissions.total + this.fields.total}`;
  }

  async setTotals(authHeader: GraphQLClientRequestHeaders, seq: number): Promise<void> {
    await this.setSubmissionsTotal(authHeader, seq);
    await this.setFieldsTotal(authHeader, seq);
  }

  async setFieldsTotal(authHeader: GraphQLClientRequestHeaders, seq: number): Promise<void> {
    if (this.fields.total === 0) {
      const { count } = (await totalFieldsRequest(authHeader, seq)).app_submission_fields_aggregate.aggregate;
      this.fields = { ...this.fields, total: count };
    }
  }

  async setSubmissionsTotal(authHeader: GraphQLClientRequestHeaders, seq: number): Promise<void> {
    if (this.submissions.total === 0) {
      const { count } = (await totalSubmissionRequest(authHeader, seq)).app_submissions_aggregate.aggregate;
      this.submissions = { ...this.submissions, total: count };
    }
  }
}
