import { useTranslation } from "react-i18next";
import { extractIBAN, friendlyFormatIBAN, validateIBAN } from "ibantools";
import { isEmpty } from "lodash-es";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import WidgetContainer from "../WidgetContainer";

export type WidgetIBANProperties = {
  required: boolean;
  label_text: string;
};

export type IBANResult = {
  ibanNumber: string;
};

const WidgetIBAN: Widget<WidgetIBANProperties, WidgetResult<IBANResult>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();

  const updateState = (value: string): void => {
    const iban = extractIBAN(value);
    setFieldState({ ibanNumber: iban.valid ? friendlyFormatIBAN(iban.iban) : value });
  };

  return (
    <WidgetContainer fieldState={fieldState} name="IBAN_FIELD">
      <TextInput
        name={fieldState.uniqueFieldId}
        value={fieldState.value.rawValue?.ibanNumber || ""}
        errorMessage={fieldState.error}
        disabled={readOnly}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        placeholder={t("IBAN_PLACEHOLDER_TEXT")}
        onChange={(e) => updateState(e.target.value)}
        leftIcon={{ name: "CreditCardIcon" }}
      />
    </WidgetContainer>
  );
};

WidgetIBAN.defaultValue = (_properties, defaultMeta: any): WidgetResult<IBANResult> => ({
  type: "object",
  meta: {
    widget: "iban",
    ...defaultMeta,
  },
});

WidgetIBAN.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && isEmpty(val?.ibanNumber)) {
    return t("VALIDATION_REQUIRED");
  }

  const trimmedIban = val?.ibanNumber.replace(/\s/g, "") || "";
  if (!isEmpty(val?.ibanNumber) && !validateIBAN(trimmedIban).valid) {
    return t("VALIDATION_IBAN_INVALID");
  }

  return undefined;
};

export default WidgetIBAN;
