import { FC, FocusEvent, Ref, useRef } from "react";
import classNames from "classnames";
import { mergeRefs } from "react-merge-refs";
import { useTranslation } from "react-i18next";
import { Label, Legend } from "../Label/Label";
import { Text } from "../Text/Text";
import { Feedback } from "../Feedback/Feedback";
import { IconButton } from "../IconButton/IconButton";
import { noop } from "../../../utils/noop";

export type DateTimeValue = {
  date?: string;
  time?: string;
};

export type DateTimeInputProps = {
  name: string;
  label: string;
  type?: "date" | "time" | "datetime";
  inputRef?: Ref<any>;
  value?: DateTimeValue;
  description?: string;
  required?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: (newVal: DateTimeValue) => void;
  onBlur?: (e: FocusEvent<any>) => void;
  onClear?: () => void;
  showClearBtn?: boolean;
  clearLabel?: string;
  className?: string;
};

export const DateTimeInput: FC<DateTimeInputProps> = ({
  name,
  label,
  type = "date",
  inputRef,
  value,
  description,
  required,
  errorMessage,
  disabled = false,
  onChange = noop,
  onBlur = noop,
  onClear = noop,
  showClearBtn = false,
  clearLabel,
  className,
}) => {
  const { t } = useTranslation();
  const dateInputRef = useRef<HTMLInputElement>(null);
  const timeInputRef = useRef<HTMLInputElement>(null);

  const inputClasses = classNames(
    "datetime-picker block h-12 w-full rounded border-1.5 p-3.5 text-sm transition",
    disabled
      ? "cursor-not-allowed bg-gray-100"
      : "hover:ring-0.5 focus:border-blue-500 focus:ring-0.5 focus:ring-blue-500",
    errorMessage && !disabled
      ? "border-red-500 bg-red-100 hover:ring-red-500 focus:bg-white"
      : "border-gray-200 hover:ring-gray-200",
    value?.date || value?.time ? "text-gray-700" : "text-gray-400",
  );
  const iconPositionClasses = "absolute top-1 right-1 flex items-center pointer-events-none";

  const dateRef = inputRef ? mergeRefs([dateInputRef, inputRef]) : dateInputRef;
  const timeRef = inputRef && type === "time" ? mergeRefs([timeInputRef, inputRef]) : timeInputRef;

  const dateAriaLabel = type === "datetime" ? `${label} - ${t("DATE")}` : label;
  const timeAriaLabel = type === "datetime" ? `${label} - ${t("TIME")}` : label;

  const content = (
    <div className="flex gap-x-2">
      {(type === "date" || type === "datetime") && (
        <div className="relative flex-1">
          <input
            id={name}
            ref={dateRef}
            type="date"
            className={inputClasses}
            value={value?.date || ""}
            disabled={disabled}
            onChange={(event) => {
              onChange({
                date: event.target.value,
                time: timeInputRef.current?.value,
              });
            }}
            onBlur={onBlur}
            aria-label={dateAriaLabel}
          />
          <div className={iconPositionClasses}>
            <IconButton aria-hidden="true" size="md" disabled={disabled} icon="CalendarIcon" tabIndex={-1} />
          </div>
        </div>
      )}
      {(type === "time" || type === "datetime") && (
        <div className="relative flex-1">
          <input
            id={name}
            ref={timeRef}
            type="time"
            className={inputClasses}
            value={value?.time || ""}
            disabled={disabled}
            onChange={(event) => {
              onChange({
                date: dateInputRef.current?.value,
                time: event.target.value,
              });
            }}
            onBlur={onBlur}
            aria-label={timeAriaLabel}
          />
          <div className={iconPositionClasses}>
            <IconButton aria-hidden="true" size="md" disabled={disabled} icon="ClockIcon" tabIndex={-1} />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className={className}>
      {type === "datetime" ? (
        <Legend
          id={name}
          clickFocus={dateInputRef}
          showClearBtn={showClearBtn}
          onClear={onClear}
          clearLabel={clearLabel}
          label={label}
          required={required}
        >
          {content}
        </Legend>
      ) : (
        <>
          <Label
            htmlFor={name}
            showClearBtn={showClearBtn}
            onClear={onClear}
            clearLabel={clearLabel}
            label={label}
            required={required}
          />
          {content}
        </>
      )}

      {errorMessage && <Feedback status="error" message={errorMessage} />}
      {description && !errorMessage && (
        <Text className="mt-1" size="sm" color="medium">
          {description}
        </Text>
      )}
    </div>
  );
};
