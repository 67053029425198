import { useEffect } from "react";
import { Keyboard } from "@capacitor/keyboard";
import scrollIntoView from "scroll-into-view-if-needed";
import { Capacitor } from "@capacitor/core";
import { noop } from "../utils/noop";

const useScrollActiveFieldIntoView = (): void => {
  useEffect(() => {
    if (!Capacitor.isPluginAvailable("Keyboard")) {
      return noop;
    }
    const listener = Keyboard.addListener("keyboardDidShow", () => {
      const element = document.activeElement;
      if (element) {
        scrollIntoView(element, {
          scrollMode: "if-needed",
          behavior: "smooth",
          block: "center",
        });
      }
    });

    return (): void => {
      listener.then((it) => it.remove());
    };
  }, []);
};

export default useScrollActiveFieldIntoView;
