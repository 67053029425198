import { FC } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon, IconName } from "../Icon/Icon";
import { Badge, BadgeProps } from "../Badge/Badge";
import { Title } from "../Title/Title";

export type SideMenuOption = {
  href: string;
  icon: IconName;
  label: string;
  disabled?: boolean;
  badge?: BadgeProps;
};

export type SideMenuProps = {
  className?: string;
  title: string;
  showTitle?: boolean;
  options: SideMenuOption[];
  logoSrc?: string;
  altOptions?: SideMenuOption[];
  selected: string;
};

export const SideMenu: FC<SideMenuProps> = ({
  title,
  showTitle = true,
  logoSrc,
  className,
  options,
  altOptions,
  selected,
}) => {
  const { t } = useTranslation();
  const classes = classNames("flex min-h-screen w-52 flex-col justify-between bg-gray-100 p-4", className);

  return (
    <nav aria-label={t("SIDE_MENU")} className={classes}>
      <div>
        <div className="flex items-center gap-x-2 pl-2 pt-safe">
          {logoSrc && <img src={logoSrc} alt={title} className="shrink-0" />}
          {showTitle && (
            <Title as="h1" size="xl" className="truncate">
              {title}
            </Title>
          )}
        </div>
        <ul className="mt-4 space-y-1">
          {options.map((option) => (
            <SideMenuItem key={option.href} active={option.href === selected} option={option} />
          ))}
        </ul>
      </div>

      {altOptions && (
        <ul>
          {altOptions.map((option) => (
            <SideMenuItem key={option.href} active={option.href === selected} option={option} />
          ))}
        </ul>
      )}
    </nav>
  );
};

type SideMenuItemProps = {
  active: boolean;
  option: SideMenuOption;
};

const SideMenuItem: FC<SideMenuItemProps> = ({ active, option }) => {
  const classes = classNames(
    "group flex w-full select-none appearance-none items-center justify-between font-semibold outline-none focus-visible:ring",
    "overflow-hidden whitespace-nowrap rounded-lg px-2 py-2.5",
    active && "bg-gray-200",
    option.disabled
      ? "cursor-not-allowed text-gray-400"
      : "text-gray-700 active:!bg-gray-700 active:text-white pointer:hover:bg-gray-300",
  );

  return (
    <li aria-hidden={option.disabled}>
      <SideMenuLink option={option} className={classes}>
        <div className="flex min-w-0 items-center gap-x-2">
          <Icon name={option.icon} type={active ? "solid" : "outline"} className="shrink-0" />
          <span className="truncate">{option.label}</span>
        </div>
        {option.badge && !option.disabled && <Badge {...option.badge} />}
      </SideMenuLink>
    </li>
  );
};

type SideMenuLinkProps = {
  option: SideMenuOption;
  className: string;
  children: React.ReactNode;
};
const SideMenuLink = ({ option, className, children }: SideMenuLinkProps): JSX.Element =>
  option.disabled ? (
    <div className={className}>{children}</div>
  ) : (
    <Link className={className} to={option.href}>
      {children}
    </Link>
  );
