import { FC, MutableRefObject, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { SubmissionSummary } from "../types/Submission";
import SentSubmissionDetail from "./SentSubmissionDetail";
import { Drawer } from "../storybook/components/Drawer/Drawer";

type SentSubmissionDetailDrawerProps = {
  submissionSummary: SubmissionSummary | undefined;
  onClose: () => void;
  open: boolean;
};

const SentSubmissionDetailDrawer: FC<SentSubmissionDetailDrawerProps> = ({ submissionSummary, onClose, open }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialFocus: MutableRefObject<any> = useRef(null);

  return (
    <Drawer
      header={{
        kind: "simple",
        title: submissionSummary?.formName ?? "",
        button: { kind: "icon", icon: "XIcon", onClick: onClose },
      }}
      open={open}
      initialFocus={initialFocus}
      onClose={onClose}
      footer={{
        kind: "default",
        primaryButton: {
          label: t("OPEN_FORM"),
          onClick: () => navigate(`/sent/${submissionSummary?.id}`),
        },
      }}
      contentPadding={false}
    >
      <SentSubmissionDetail submissionSummary={submissionSummary} />
    </Drawer>
  );
};

export default SentSubmissionDetailDrawer;
