import { FC } from "react";
import classNames from "classnames";

type SegmentOption = {
  id: string;
  label: string;
};

export type SegmentProps = {
  className?: string;
  options: SegmentOption[];
  selected?: string;
  onSelect?: (id: string) => void;
};

export const Segment: FC<SegmentProps> = ({ selected = "", className, onSelect, options, ...props }) => {
  const classes = classNames("flex items-center justify-between rounded-lg bg-brand-100 p-0.5", className);

  const getOptionClasses = (option: SegmentOption): string =>
    classNames(
      "min-w-0 flex-auto break-words rounded-md px-0.5 py-1 text-center text-xs font-medium outline-none transition-all ease-in-out",
      option.id === selected ? "bg-white text-brand-700 shadow-md" : "text-gray-700 focus-visible:ring",
    );

  return (
    <div className={classes} {...props}>
      {options.map((option) => (
        <button
          key={option.id}
          className={getOptionClasses(option)}
          onClick={() => onSelect && onSelect(option.id)}
          disabled={option.id === selected}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};
