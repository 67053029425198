import { FC, useRef, useState } from "react";
import { Marker, useMapEvents } from "react-leaflet";
import { Icon, LatLngLiteral, Marker as LeafletMarker } from "leaflet";

type ClickableMarkerProps = {
  position?: LatLngLiteral;
  setPosition?: (val: LatLngLiteral) => void;
};

const ClickableMarker: FC<ClickableMarkerProps> = ({ position, setPosition }) => {
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout>();
  useMapEvents({
    click: ({ latlng }) => {
      if (!clickTimeout) {
        setClickTimeout(
          setTimeout(() => {
            setPosition && setPosition(latlng);
            setClickTimeout(undefined);
          }, 500),
        );
      }
    },
    dblclick: () => {
      clearTimeout(clickTimeout);
      setClickTimeout(undefined);
    },
  });
  const markerRef = useRef<LeafletMarker>(null);

  const markerIcon = new Icon({
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    iconUrl: "/assets/marker.svg",
  });

  return position ? <Marker position={position} ref={markerRef} icon={markerIcon} /> : null;
};

export default ClickableMarker;
