import stringLength from "string-length";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import useSyncedState from "../../hooks/useSyncedState";
import WidgetContainer from "../WidgetContainer";

export type WidgetTextProperties = {
  required: boolean;
  label_text: string;
  max_length?: number;
  min_length?: number;
  text_placeholder?: string;
  text_default_value?: string;
  type?: "text" | "password";
};

const WidgetText: Widget<WidgetTextProperties, WidgetResult<string>> = ({ fieldState, setFieldState, readOnly }) => {
  const [localState, setLocalState] = useSyncedState(fieldState.value.rawValue);

  return (
    <WidgetContainer name="TEXT_FIELD" fieldState={fieldState}>
      <TextInput
        name={fieldState.uniqueFieldId}
        errorMessage={fieldState.error}
        value={localState || ""}
        disabled={readOnly}
        onChange={(e) => setLocalState(e.target.value)}
        onBlur={() => setFieldState(localState)}
        type={fieldState.properties.type ?? "text"}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        placeholder={fieldState.properties.text_placeholder}
      />
    </WidgetContainer>
  );
};

WidgetText.defaultValue = (properties, defaultMeta): WidgetResult<string> => ({
  type: "string",
  rawValue: properties.text_default_value,
  meta: {
    widget: "text",
    ...defaultMeta,
  },
});

WidgetText.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  const max = properties.max_length;
  const min = properties.min_length;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  if (val && min && stringLength(val) < min) {
    return t("VALIDATION_TEXT_MIN", { min });
  }

  if (val && max && stringLength(val) > max) {
    return t("VALIDATION_TEXT_MAX", { max });
  }

  return undefined;
};

export default WidgetText;
