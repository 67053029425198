import { useTranslation } from "react-i18next";
import { Widget } from "../../types/Widget";
import { to24hTime } from "../../utils/dateUtil";
import { WidgetResult } from "../../types/Field";
import { DateTimeInput } from "../../storybook/components/DateTimeInput/DateTimeInput";
import WidgetContainer from "../WidgetContainer";

export type WidgetTimeProperties = {
  required: boolean;
  label_text: string;
  now_as_default: boolean;
};

const WidgetTime: Widget<WidgetTimeProperties, WidgetResult<string>> = ({ fieldState, setFieldState, readOnly }) => {
  const { t } = useTranslation();

  return (
    <WidgetContainer fieldState={fieldState} name="TIME_FIELD">
      <DateTimeInput
        name={fieldState.uniqueFieldId}
        type="time"
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        value={{ time: fieldState.value.rawValue }}
        errorMessage={fieldState.error}
        disabled={readOnly}
        onClear={() => setFieldState(undefined)}
        onChange={(value) => setFieldState(value.time)}
        showClearBtn={!!fieldState.value.rawValue && !readOnly}
        clearLabel={t("CLEAR")}
      />
    </WidgetContainer>
  );
};

WidgetTime.defaultValue = (properties, defaultMeta): WidgetResult<string> => ({
  type: "time",
  rawValue: properties.now_as_default ? to24hTime(new Date()) : undefined,
  meta: {
    widget: "time",
    ...defaultMeta,
  },
});

WidgetTime.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }
  return undefined;
};

export default WidgetTime;
