import { ComponentPropsWithoutRef, FC } from "react";
import classNames from "classnames";
import { Icon, IconName } from "../Icon/Icon";
import { Spinner } from "../Spinner/Spinner";

export type ButtonVariant =
  | "default"
  | "primary"
  | "destructive"
  | "transparentWhite"
  | "transparentMedium"
  | "transparentBrand";

export type GenericButtonProps = {
  variant?: ButtonVariant;
  loading?: boolean;
} & ComponentPropsWithoutRef<"button">;

export type ButtonProps = {
  label?: string;
  icon?: IconName;
  iconType?: "solid" | "outline";
  iconAlign?: "left" | "right";
  iconColor?: "default" | "destructive" | "medium";
  block?: boolean;
  size?: "lg" | "md";
} & GenericButtonProps;

export const Button: FC<ButtonProps> = ({
  variant = "default",
  block = false,
  label,
  size = "lg",
  icon,
  iconType = "solid",
  iconAlign = "left",
  iconColor = "default",
  loading = false,
  disabled,
  className,
  ...props
}) => {
  const isClickable = !loading && !disabled;
  const classes = classNames(
    "relative flex min-w-0 select-none items-center justify-center space-x-1.5 font-semibold outline-none transition",
    iconAlign === "right" && "flex-row-reverse space-x-reverse",
    block ? "w-full" : "max-w-full",
    isClickable && "pointer:focus-visible:ring",
    disabled && "cursor-not-allowed pointer-events-none",

    size === "lg" && {
      "h-12 rounded-lg": true,
      "px-5": label,
      "px-3": !label,
      "w-12": !label && icon,
    },
    size === "md" && { "h-10 px-2 rounded-lg": true, "w-10": !label && icon },

    variant === "default" && {
      "bg-brand-100": true,
      "text-gray-700 active:text-brand-50 active:!bg-brand-600 pointer:hover:bg-brand-200": isClickable,
      "text-gray-400": disabled,
      "text-gray-500": loading,
    },

    variant === "primary" && {
      "bg-brand-600 text-white": !disabled,
      "active:!bg-brand-900 active:text-brand-50 pointer:hover:bg-brand-700": isClickable,
      "bg-brand-100 text-gray-400": disabled,
    },

    variant === "destructive" && {
      "bg-red-600 text-white": !disabled,
      "active:!bg-red-900 active:text-gray-50 pointer:hover:bg-red-700": isClickable,
      "bg-red-200 text-gray-400": disabled,
    },

    variant === "transparentWhite" && {
      "active:!bg-gray-900/10 pointer:hover:bg-gray-900/5": isClickable,
      "text-white": !disabled,
      "bg-gray-900/10 text-gray-900/40": disabled,
    },

    variant === "transparentBrand" && {
      "active:!bg-gray-900/10 pointer:hover:bg-gray-900/5 active:!text-brand-900 pointer:hover:text-brand-700":
        isClickable,
      "text-brand-600": !disabled,
      "text-gray-900/40": disabled,
    },

    variant === "transparentMedium" && {
      "active:!bg-gray-900/10 active:text-gray-900 pointer:hover:bg-gray-900/5": isClickable,
      "text-gray-700": !disabled || loading,
      "bg-gray-900/10 text-gray-900/40": disabled,
    },

    className,
  );

  const iconClasses = classNames(
    "size-6 shrink-0",
    "pointer-events-none",
    loading && "invisible",
    !disabled && {
      "text-red-700": iconColor === "destructive",
      "text-gray-500": iconColor === "medium",
    },
  );
  const labelClasses = classNames("truncate", { invisible: loading });

  return (
    <button type="button" className={classes} disabled={!isClickable} aria-label={label} {...props}>
      {icon && <Icon className={iconClasses} name={icon} type={iconType} />}
      {label && <span className={labelClasses}>{label}</span>}
      {loading && <Spinner color="current" className="absolute size-6" />}
      {props.children}
    </button>
  );
};
