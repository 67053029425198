import { Submission } from "../types/Submission";
import { Field } from "../types/Field";
import { useMoreAppClient } from "../context/MoreAppContext";
import { copySubmission, ensureFields } from "../utils/submissionUtil";
import usePublishedFormVersion from "./usePublishedFormVersion";
import { filterExcludedFields, filterUnpublishedFields } from "../utils/copyUtil";
import useFieldCollection from "./useFieldCollection";
import useSubmissionCollection from "./useSubmissionCollection";
import useDeviceInfo from "./useDeviceInfo";
import useUserId from "./useUserId";
import useDatabase from "./useDatabase";
import { isEmpty } from "lodash-es";
import { sleep } from "../utils/sleepUtil";

type UseSubmissionCopyResult = {
  copy: () => Promise<string | undefined>;
};

const useSubmissionCopy = (submission?: Submission, fields?: Field[], formId?: string): UseSubmissionCopyResult => {
  const submissionCollection = useSubmissionCollection();
  const fieldCollection = useFieldCollection();
  const client = useMoreAppClient();
  const userId = useUserId();
  const { id: deviceId } = useDeviceInfo();
  const { formVersion } = usePublishedFormVersion(submission?.customerId, formId);
  const { database: db } = useDatabase();

  const copy = async (): Promise<string | undefined> => {
    if (!submission || !fields || !formVersion || !formId || !userId) {
      return undefined;
    }

    // Filter out fields that are no longer available in published FormVersion
    const remainingFields = filterUnpublishedFields(formVersion, fields);

    // Filter out fields that are excluded by Search Settings
    const allowedFields = filterExcludedFields(formVersion, remainingFields);
    const copied = await copySubmission(submission, allowedFields, formVersion, client!, userId, deviceId);
    await submissionCollection?.upsert(copied.submission);

    // Add new (nested) fields that are missing based on this FormVersion, like:
    // - new root fields
    // - new nested fields for existing entries
    // - excluded fields
    // otherwise, it won't be "complete" (and block loading) because we're missing fields (see useSubmissionComplete)
    const newFields = await ensureFields(
      copied.submission.id,
      formId,
      formVersion,
      deviceId,
      userId,
      copied.fields,
      [], // we don't want to fill missing fields with 'remembered values' here, that would be confusing
    );
    const allFields = [...copied.fields, ...newFields];
    const unsyncedParentFields = allFields
      .filter((field) => !isEmpty(field.entries)) // parent fields
      .map((field) => ({ id: field.id, submissionId: copied.submission.id }));

    await db?.unsyncedparentfields.bulkUpsert(unsyncedParentFields); // required to send parents with fields for entries that aren't synced yet (prevent "Foreign key violation")
    // See DEV-5931: decreases the chance that fields are pushed BEFORE the matching submission exists, resulting in foreign key violations
    await sleep(500);
    await fieldCollection?.bulkUpsert(allFields);

    return copied.submission.id;
  };
  return { copy };
};

export default useSubmissionCopy;
