import { ChangeEvent, RefObject, useRef } from "react";
import { isNil } from "lodash-es";
import uuidv4 from "../utils/uuid";
import { FileResult } from "../types/Widget";
import { UploadStatus, WidgetResult } from "../types/Field";
import useFileHandler, { UploadResult } from "./useFileHandler";
import { getExtensionBlob, isExtensionAllowedMimeType } from "../utils/fileUtil";
import { useUploadManager } from "./useUploadManager";
import { FieldState } from "../types/SubmissionState";
import { WidgetProperties } from "../types/FormVersion";
import useStateSubmissionId from "../state/useStateSubmissionId";

export type PersistWithUploadStatusFn<T> = (rawValue?: T, uploadStatus?: UploadStatus) => void;

type UseFileWidgetResult = {
  inputFile: RefObject<HTMLInputElement>;
  uploadPercentage: number;
  isAttemptingOrUploaded?: boolean;
  storeFileOnChange: (event: ChangeEvent<HTMLInputElement>) => Promise<UploadResult | undefined>;
  cancelUpload: () => Promise<void>;
  retryUpload: () => Promise<void>;
  removeFile: () => Promise<void>;
};

const useFileWidget = (
  persistWithUploadStatus: PersistWithUploadStatusFn<FileResult>,
  fieldState: FieldState<WidgetProperties, WidgetResult<FileResult>>,
  allowedMimeTypeArray?: string[],
): UseFileWidgetResult => {
  const { storeFileUri } = useFileHandler();
  const submissionId = useStateSubmissionId();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const { current, isUploading } = useUploadManager();

  const isUploadingForThisField = isUploading && current?.id === fieldState.value.rawValue?.id;
  const uploadPercentage = isUploadingForThisField ? (current?.uploadPercentage ?? 0) : 0;
  const { uploadStatus } = fieldState.value.meta;
  const isAttemptingOrUploaded = !isNil(uploadStatus) && uploadStatus !== "aborted";

  const storeFileOnChange = async (event: ChangeEvent<HTMLInputElement>): Promise<UploadResult | undefined> => {
    if (!event.currentTarget.files || !event.currentTarget.files[0]) {
      return undefined;
    }
    return storeFile(event.currentTarget.files[0]);
  };

  const storeFile = async (file: File): Promise<UploadResult> => {
    const id = uuidv4();
    const extension = getExtensionBlob(file);

    if (allowedMimeTypeArray && !isExtensionAllowedMimeType(extension ?? "", allowedMimeTypeArray)) {
      return { fileResult: { id, name: file.name, extension }, uploadStatus: "invalid_extension" };
    }

    return storeFileUri(URL.createObjectURL(file), submissionId, id, file.name);
  };

  const removeFile = async (): Promise<void> => {
    if (inputFile.current) {
      inputFile.current.value = "";
      persistWithUploadStatus(undefined, undefined);
    }
  };

  const cancelUpload = async (): Promise<void> => {
    current?.abortController.abort();
    await removeFile();
  };

  const retryUpload = async (): Promise<void> => {
    const files = inputFile.current?.files;
    if (files && files[0]) {
      await storeFile(files[0]);
    }
  };

  return {
    inputFile,
    isAttemptingOrUploaded,
    uploadPercentage,
    storeFileOnChange,
    cancelUpload,
    retryUpload,
    removeFile,
  };
};
export default useFileWidget;
