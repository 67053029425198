import React, { FC } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon, IconName } from "../Icon/Icon";
import { Badge, BadgeProps } from "../Badge/Badge";

export type TabBarOption = {
  href: string;
  icon: IconName;
  label: string;
  badge?: BadgeProps;
};

export type TabBarProps = {
  className?: string;
  options: TabBarOption[];
  selected: string;
};

export const TabBar: FC<TabBarProps> = ({ options, selected, className }) => {
  const { t } = useTranslation();
  const classes = classNames("grid auto-cols-fr grid-flow-col border-t-2 bg-white", className);

  const getOptionClasses = (active: boolean): string =>
    classNames(
      "flex select-none appearance-none flex-col items-center outline-none focus-visible:ring active:!bg-gray-900/10 pointer:hover:bg-gray-900/5",
      "flex-1 p-2.5 pt-4 text-center text-2xs",
      "translate-x-0 transition-all ease-in-out",
      active
        ? "text-brand-600 active:!text-brand-800 pointer:hover:text-brand-700"
        : "text-gray-700 active:!text-gray-900 pointer:hover:text-gray-800",
    );

  const getOptionHeaderClasses = (active: boolean): string =>
    classNames("absolute -top-0.5 h-0.5 w-14 rounded bg-brand-600 transition-transform", !active && "scale-0");

  return (
    <nav aria-label={t("TAB_BAR")}>
      <ul className={classes}>
        {options.map((option) => (
          <li key={option.href}>
            <Link to={option.href} className={getOptionClasses(option.href === selected)}>
              <div className={getOptionHeaderClasses(option.href === selected)} />
              <Icon name={option.icon} type={option.href === selected ? "solid" : "outline"} />
              {option.badge && <Badge {...option.badge} className="absolute -mt-2 ml-10" />}
              <span className="mt-1 max-w-full truncate text-2xs">{option.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
