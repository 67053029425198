import useDeviceInfo from "./useDeviceInfo";

type UseAfterLeaveFocus = {
  afterLeaveFocus: () => void;
};

export const useAfterLeaveFocus = (
  onCloseFocusId: string | undefined,
  iosDelay = 100,
  delay = 1,
  preventScroll = false,
): UseAfterLeaveFocus => {
  const { platform } = useDeviceInfo();

  const afterLeaveFocus = (): void => {
    if (!onCloseFocusId) {
      return;
    }

    setTimeout(
      () => {
        document.getElementById(onCloseFocusId)?.focus({ preventScroll });
      },

      // When a Drawer or Modal (Dialog) removes a button that was focused before opening the Drawer or Modal, the focus
      // of that element is lost. HeadlessUI will autofocus the last element that was focused before the removed button.
      // This is a workaround to set the focus to the correct element after the Drawer or Modal is closed.

      // iOS needs an increased delay to set the focus correctly as iOS has some autofocus features that can interfere with
      // a fast focus change.

      // Safari has the same problem as iOS, but the delay is as not as bad.
      // A delay of 1, works.
      // A delay of 0 does not work.
      platform === "ios" ? iosDelay : delay,
    );
  };

  return { afterLeaveFocus };
};
