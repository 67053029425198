import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Content from "../Content";
import Form from "../Form";
import { FormField, FormVersion } from "../../types/FormVersion";
import { useSubmissionStoreInitializer } from "../../state/useSubmissionStoreInitializer";
import { SubmissionStoreOptions } from "../../state/useSubmissionStore";
import { submissionFormDataToFields } from "../../types/Field";
import { getStaticDefaultValues } from "../../utils/submissionUtil";
import uuidv4 from "../../utils/uuid";
import useDeviceInfo from "../../hooks/useDeviceInfo";
import { findFormVersions } from "../../utils/FormEngine";
import SpinnerWithMessage from "../../storybook/components/Spinner/SpinnerWithMessage";
import { buildFormState } from "../../utils/fieldStateUtil";

type SearchSubmissionsFormProps = {
  formVersion: FormVersion;
};

const options: SubmissionStoreOptions = {
  readOnly: false,
  persist: false,
  validate: false,
};

const submissionId = uuidv4();

const SearchSubmissionsForm: FC<SearchSubmissionsFormProps> = ({ formVersion }) => {
  const { formId } = formVersion;
  const searchFormVersion = useMemo(
    () => ({
      ...formVersion,
      fields: formVersion.fields
        .filter((item) => formVersion.settings.searchSettings?.fields?.[item.uid])
        .map(makeOptional),
      rules: [],
    }),
    [formVersion],
  );
  const { id: deviceId } = useDeviceInfo();
  const defaultValues = getStaticDefaultValues(searchFormVersion, submissionId);
  const fields = submissionFormDataToFields(defaultValues);
  const formVersions = useMemo(() => findFormVersions(formVersion), [formVersion]);
  const formState = useMemo(
    () => buildFormState(searchFormVersion, formVersions, fields, submissionId, deviceId, defaultValues),
    [],
  );
  const { initialized } = useSubmissionStoreInitializer(submissionId, formId, formState, searchFormVersion, options);
  const { t } = useTranslation();

  if (!initialized) {
    <SpinnerWithMessage message={t("RETRIEVING_SEARCH_FIELDS")} />;
  }

  return (
    <Content asRows>
      <Form submissionId={submissionId} formVersion={searchFormVersion} />
    </Content>
  );
};

const makeOptional = (field: FormField<any>): FormField<any> => ({
  ...field,
  properties: { ...field.properties, required: false },
});

export default SearchSubmissionsForm;
