import { UseQueryResult } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { MoreAppError } from "../context/MoreAppContext";

type BaseInlineLoaderProps<T> = {
  obj: UseQueryResult<T, MoreAppError>;
  opts?: { width: string };
};

type InlineLoaderPropsField<T, K extends keyof T> = {
  field: K;
  format?: never;
} & BaseInlineLoaderProps<T>;

type InlineLoaderPropsFormat<T> = {
  field?: never;
  format: (value: T) => string;
} & BaseInlineLoaderProps<T>;

const InlineLoader = <T, K extends keyof T>({
  obj,
  field,
  format,
  opts = { width: "w-full" },
}: InlineLoaderPropsField<T, K> | InlineLoaderPropsFormat<T>): JSX.Element => {
  const { t } = useTranslation();

  if (obj.isLoading) {
    return (
      <span className={`mx-auto inline-block max-w-sm ${opts.width}`}>
        <span className="flex animate-pulse space-x-4">
          <span className="flex-1 space-y-4 py-1">
            <span className="block h-4 rounded bg-gray-200" />
          </span>
        </span>
      </span>
    );
  }

  if (obj.isError) {
    return <>{t("LOADER_FAILURE", { field })}</>;
  }

  if (format) {
    return <>{format(obj.data)}</>;
  }

  return <>{String(obj.data[field])}</>;
};

export default InlineLoader;
