import { ComponentPropsWithoutRef, FC, Fragment, MouseEventHandler, ReactElement } from "react";
import classNames from "classnames";
import { Title } from "../Title/Title";
import { Icon, IconName } from "../Icon/Icon";
import { Indicator } from "../Indicator/Indicator";
import { BannerProps } from "../Banner/Banner";
import { IconButton } from "../IconButton/IconButton";
import { TextButton } from "../TextButton/TextButton";

export type HeaderProps = {
  className?: string;
  title: string;
  actions?: HeaderButtonProps[];
  backBtn?: { ariaLabel: string; onClick: () => void };
  testBtn?: { ariaLabel: string; onClick: () => void };
  onClick?: MouseEventHandler<HTMLButtonElement>;
  showOnClickBtn?: boolean;
  showIndicator?: boolean;
  indicatorLabel?: string;
  banner?: ReactElement<BannerProps>;
};

export type HeaderButtonProps = HeaderTextButtonProps | HeaderIconButtonProps;

export type HeaderIconButtonProps = {
  kind: "icon";
  iconType?: "solid" | "outline";
  icon: IconName;
} & ComponentPropsWithoutRef<"button">;

export type HeaderTextButtonProps = {
  kind: "text";
  label: string;
} & ComponentPropsWithoutRef<"button">;

export const Header: FC<HeaderProps> = ({
  className,
  title,
  actions = [],
  backBtn,
  onClick,
  showOnClickBtn,
  showIndicator,
  indicatorLabel,
  banner,
}) => {
  const classes = classNames(
    "flex h-13 items-center justify-between gap-x-2.5 border-b py-2",
    showOnClickBtn ? "pl-2 pr-4" : "px-4",
    className,
  );

  const headerTitle = (): JSX.Element => {
    if (showOnClickBtn) {
      return (
        <WorkspaceSwitcherButton
          title={title}
          onClick={onClick}
          showIndicator={showIndicator}
          indicatorLabel={indicatorLabel}
        />
      );
    }
    if (backBtn) {
      return (
        <div className="relative flex min-w-0 items-center">
          {backBtn && (
            <BackButton ariaLabel={backBtn.ariaLabel} onClick={backBtn.onClick} className="-ml-2 lg:hidden" />
          )}
          <Title as="h1" className="truncate" size="xl">
            {title}
          </Title>
        </div>
      );
    }
    return (
      <Title as="h1" className="truncate" size="xl">
        {title}
      </Title>
    );
  };

  return (
    <>
      <div className={classes}>
        <div className="relative flex min-w-0 items-center">{headerTitle()}</div>

        <div className="flex shrink-0 gap-x-4">
          {actions.map((action, i) => (
            <Fragment key={i}>
              {action.kind === "icon" && (
                <IconButton {...action} variant="transparentMedium" iconType={action.iconType ?? "solid"} />
              )}
              {action.kind === "text" && <TextButton {...action} variant="transparentBrand" />}
            </Fragment>
          ))}
          {backBtn && (
            <BackButton
              ariaLabel={backBtn.ariaLabel}
              onClick={backBtn.onClick}
              icon="XIcon"
              className="hidden lg:flex"
            />
          )}
        </div>
      </div>
      {banner}
    </>
  );
};

type BackButtonProps = {
  className?: string;
  ariaLabel?: string;
  onClick?: () => void;
  icon?: IconName;
};
const BackButton: FC<BackButtonProps> = ({ className, ariaLabel, onClick, icon = "ChevronLeftIcon" }) => (
  <IconButton
    className={className}
    aria-label={ariaLabel}
    variant="transparentMedium"
    iconType="outline"
    size="md"
    icon={icon}
    onClick={onClick}
  />
);

type WorkspaceSwitcherButtonProps = {
  title?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  showIndicator?: boolean;
  indicatorLabel?: string;
};
const WorkspaceSwitcherButton: FC<WorkspaceSwitcherButtonProps> = ({
  title,
  onClick,
  showIndicator,
  indicatorLabel,
}) => {
  const classes = classNames(
    "group inline-flex items-center gap-2.5 truncate rounded-lg py-1 pl-2 pr-1.5 text-xl font-bold text-gray-700",
    "outline-none focus-visible:ring active:!bg-gray-900/10 active:text-gray-900 pointer:hover:bg-gray-900/5",
  );

  return (
    <button className={classes} onClick={onClick}>
      <Title as="h1" className="truncate group-active:text-gray-900" size="xl">
        {title}
      </Title>
      <Icon name="ChevronDownIcon" className="shrink-0" />
      {showIndicator && (
        <Indicator size="sm" className="pointer-events-none absolute right-0.5 top-0.5" ariaLabel={indicatorLabel} />
      )}
    </button>
  );
};
