import { FC } from "react";
import classNames from "classnames";
import { Theme, Themes } from "../../../themes";
import { Icon, IconName } from "../../Icon/Icon";

export type NavIconProps = {
  name?: IconName;
  iconBackdrop?: "circle" | "folder";
  showSubIcon?: boolean;
  theme?: Theme;
  className?: string;
  disabled?: boolean;
};

export const NavIcon: FC<NavIconProps> = ({
  iconBackdrop = "circle",
  theme = Themes.Default,
  name,
  showSubIcon,
  disabled,
  className,
}) => {
  const iconContainer = classNames("flex h-10 flex-col justify-center", className);
  const iconClasses = classNames(
    "relative flex items-center justify-center p-2.5 text-white",
    iconBackdrop === "circle" && {
      "h-10 w-10 rounded-full": true,
      [theme.bgColor]: !disabled,
      "bg-brand-300": disabled,
    },
    iconBackdrop === "folder" && "top-0.5 h-7 w-10 rounded bg-gray-300",
  );
  const subIconClasses = classNames(
    "absolute bottom-0 right-0 -m-1 flex size-5 items-center justify-center rounded-full border-2 border-white p-0.5",
    disabled ? "bg-brand-300" : theme.bgColor,
  );

  return (
    <div className={iconContainer}>
      <div className={iconClasses}>
        {name && <Icon className="size-5 shrink-0" name={name} />}
        {iconBackdrop === "folder" && FolderBackdrop}
        {showSubIcon && <Icon name="CheckIcon" className={subIconClasses} />}
      </div>
    </div>
  );
};

const FolderBackdrop = (
  <>
    <div className="absolute inset-0 -mt-1.5 size-3.5 rounded-tl bg-gray-300" />
    <div className="absolute left-3.5 top-0 -mt-1.5 size-0 border-4 border-gray-300 border-r-transparent border-t-transparent" />
  </>
);
