import { useUploadManager } from "./useUploadManager";
import { FieldState } from "../types/SubmissionState";
import { WidgetProperties } from "../types/FormVersion";
import { WidgetResult } from "../types/Field";
import { FileResult } from "../types/Widget";

type UseFieldUploadStateResult = {
  uploadPercentage: number;
  isUploading: boolean;
};

const useFieldUploadState = (
  fieldState: FieldState<WidgetProperties, WidgetResult<FileResult>>,
): UseFieldUploadStateResult => {
  const { current, isUploading } = useUploadManager();

  const isUploadingForThisField = isUploading && current?.id === fieldState.value.rawValue?.id;
  const uploadPercentage = isUploadingForThisField ? (current?.uploadPercentage ?? 0) : 0;

  return { uploadPercentage, isUploading: isUploadingForThisField };
};

export default useFieldUploadState;
