import { FC, PropsWithChildren } from "react";
import classNames from "classnames";
import { Text } from "../Text/Text";
import { Title } from "../Title/Title";

export type MessageProps = {
  className?: string;
  type?: "info" | "success" | "warning" | "error";
  title?: string;
  text?: string;
};

export const Message: FC<PropsWithChildren<MessageProps>> = ({ className, type = "info", title, text, children }) => {
  const classes = classNames(
    "space-y-2 rounded-lg p-4",
    type === "info" && "bg-brand-100",
    type === "success" && "bg-green-100",
    type === "warning" && "bg-yellow-100",
    type === "error" && "bg-red-100",
    className,
  );

  return (
    <div className={classes}>
      {title && (
        <Title as="h3" size="lg" color="dark">
          {title}
        </Title>
      )}
      {text && <Text color="dark">{text}</Text>}
      {children}
    </div>
  );
};
