import { useContext } from "react";
import { RxDatabase } from "rxdb";
import { CorruptionState, DatabaseContext } from "../context/DatabaseContext";
import { GraphQLReplicator } from "../database/replication/GraphQLReplicator";
import type { DBCollections } from "../utils/databaseUtil";
import { PullProgress } from "../database/replication/PullProgress";

export type UseDatabaseResult = {
  database?: RxDatabase<DBCollections>;
  replicator?: GraphQLReplicator;
  isCorrupt?: CorruptionState;
  isInitiallySynced: boolean;
  isSyncActive: boolean;
  destroy: () => Promise<void>;
  pullProgress?: PullProgress;
  setAnonymousDatabase: (value: boolean) => void;
};

const useDatabase = (): UseDatabaseResult => {
  const {
    database,
    replicator,
    isCorrupt,
    isInitiallySynced,
    isSyncActive,
    pullProgress,
    destroy,
    setAnonymousDatabase,
  } = useContext(DatabaseContext);

  return {
    database,
    replicator,
    isCorrupt,
    isInitiallySynced,
    isSyncActive,
    destroy,
    pullProgress,
    setAnonymousDatabase,
  };
};

export default useDatabase;
