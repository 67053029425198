import { isFinite, isNil } from "lodash-es";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { NumberInput } from "../../storybook/components/NumberInput/NumberInput";
import { getFormValue } from "../../utils/numberUtil";
import { isTouchCapable } from "../../utils/deviceUtil";
import useSyncedState from "../../hooks/useSyncedState";
import WidgetContainer from "../WidgetContainer";

export type WidgetNumberProperties = {
  required: boolean;
  label_text: string;
  numeric_min?: number;
  numeric_max?: number;
  text_default_value?: number;
  text_placeholder?: string;
};

// Nine trillion is near the limit of numbers in MongoDB. Numbers above this limit become string
export const HARD_MIN_LIMIT = -9000000000000000000n; // n means BigInt
export const HARD_MAX_LIMIT = 9000000000000000000n;

const WidgetNumber: Widget<WidgetNumberProperties, WidgetResult<number>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const [localState, setLocalState] = useSyncedState(fieldState.value.rawValue);

  return (
    <WidgetContainer fieldState={fieldState} name="NUMBER_FIELD">
      <NumberInput
        name={fieldState.uniqueFieldId}
        value={localState}
        errorMessage={fieldState.error}
        onChange={(values) => setLocalState(values.floatValue)}
        onBlur={(e) => setFieldState(getFormValue(e))}
        onPlusMinus={
          isTouchCapable()
            ? (): void => {
                // Only switch real numbers and skip 0 because -0 is a quirky number
                const isSignSwitchable = !isNil(localState) && isFinite(localState) && localState !== 0;
                if (isSignSwitchable) {
                  setFieldState(localState * -1);
                }
              }
            : undefined
        }
        inputMode="decimal"
        min={fieldState.properties.numeric_min}
        max={fieldState.properties.numeric_max}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        disabled={readOnly}
        placeholder={fieldState.properties.text_placeholder}
        showThousandSeparator
      />
    </WidgetContainer>
  );
};

WidgetNumber.defaultValue = (properties, defaultMeta: any): WidgetResult<number> => ({
  type: "number",
  rawValue: properties.text_default_value,
  meta: {
    widget: "number",
    ...defaultMeta,
  },
});

WidgetNumber.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  const min = properties.numeric_min ?? HARD_MIN_LIMIT;
  const max = properties.numeric_max ?? HARD_MAX_LIMIT;

  if (required && isNil(val)) {
    return t("VALIDATION_REQUIRED");
  }

  if (!isNil(val)) {
    //  also check val against hard limits in case min and/or max from platform succeeds hard limits
    if (val < min || val < HARD_MIN_LIMIT) {
      return t("VALIDATION_NUMBER_MIN", { min: min < HARD_MIN_LIMIT ? HARD_MIN_LIMIT : min });
    }
    if (val > max || val > HARD_MAX_LIMIT) {
      return t("VALIDATION_NUMBER_MAX", { max: max > HARD_MAX_LIMIT ? HARD_MAX_LIMIT : max });
    }
  }

  return undefined;
};

export default WidgetNumber;
