import { isNil, round } from "lodash-es";
import { DateTime } from "luxon";
import { FormField, WidgetProperties } from "../types/FormVersion";
import { WidgetResult } from "../types/Field";
import { getTermFields } from "./termUtil";
import { WidgetTimeDifferenceProperties } from "../components/widgets/WidgetTimeDifference";
import { nowToISO } from "./dateUtil";
import { FieldState } from "../types/SubmissionState";

type DateTimeValue = {
  date: string;
  time: string;
};

export const seconds = (sec: number): number => sec * 1000;

export const minutes = (min: number): number => min * seconds(60);

export const hours = (hrs: number): number => hrs * minutes(60);

export const getTimeDifferenceResult = (
  formField: FormField<WidgetTimeDifferenceProperties>,
  fields: FieldState<WidgetProperties, WidgetResult<unknown>>[],
  entryId?: string,
): number | null => {
  const { start, end } = formField.properties;

  if (isNil(start) || isNil(end)) {
    return null;
  }
  const startTerm = getTermFields(fields, [start], entryId);
  const endTerm = getTermFields(fields, [end], entryId);
  if (startTerm.length === 0 || endTerm.length === 0) {
    return null;
  }
  const startTime = startTerm[0].field.value.rawValue as string | DateTimeValue;
  const endTime = endTerm[0].field.value.rawValue as string | DateTimeValue;
  if (!startTime || !endTime) {
    return null;
  }
  const msDifference = parseAsDate(endTime).diff(parseAsDate(startTime)).toMillis();
  return round(msDifference / 36e5, 2);
};

const parseAsDate = (input: string | DateTimeValue): DateTime<true> | DateTime<false> => {
  const date = typeof input === "string" ? { time: input, date: nowToISO().slice(0, 10) } : input;
  return DateTime.fromISO(`${date.date}T${date.time}`);
};
