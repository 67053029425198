export const EPOCH_DATE_ISO = new Date(0).toISOString();

export const toIsoDate = (date: Date): string =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split("T")[0];

export const to24hTime = (date: Date): string => {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const toHumanReadableDate = (date: Date | string, includeTime?: boolean, ignoreTimeZone = false): string => {
  let dateObj = typeof date === "string" ? new Date(date) : date;
  if (ignoreTimeZone) {
    dateObj = removeTimezoneOffset(dateObj);
  }
  const showYear = dateObj.getFullYear() !== new Date().getFullYear();

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    ...(showYear && { year: "numeric" }),
    ...(includeTime && { hour: "numeric", minute: "numeric" }),
  };

  return dateObj.toLocaleDateString(getUserLocale(), options);
};

export const getUserLocale = (): string => {
  const locales = getBrowserLocales();
  if (!locales || !locales.length) {
    return "en-GB";
  }
  return locales[0];
};

const getBrowserLocales = (): string[] | undefined => {
  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
  if (!browserLocales) {
    return undefined;
  }
  return browserLocales.map((locale) => locale.trim());
};

const removeTimezoneOffset = (date: Date): Date => {
  // From: https://stackoverflow.com/a/39209842
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + Math.abs(userTimezoneOffset) * Math.sign(userTimezoneOffset));
};

export const nowToISO = (): string => new Date().toISOString();
