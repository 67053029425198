import { t } from "i18next";
import { FC } from "react";
import { Modal } from "../storybook/components/Modal/Modal";

type SubmissionDeleteModalProps = {
  showModal: boolean;
  onConfirm: () => Promise<void>;
  onModalClose: () => Promise<void>;
};

const SubmissionDeleteModal: FC<SubmissionDeleteModalProps> = ({ showModal = false, onConfirm, onModalClose }) => (
  <Modal
    title={t("SUBMISSION_DELETE_MODAL_TITLE")}
    content={{
      kind: "message",
      message: t("SUBMISSION_DELETE_MODAL_DESCRIPTION"),
    }}
    open={showModal}
    onClose={onModalClose}
    buttons={[
      {
        label: t("CANCEL"),
        onClick: onModalClose,
      },
      {
        label: t("DELETE"),
        variant: "destructive",
        onClick: onConfirm,
      },
    ]}
  />
);

export default SubmissionDeleteModal;
