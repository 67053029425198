import useFolders from "./useFolders";
import { useMemo } from "react";
import { Form } from "../types/Folder";

export const useLocalForm = (formId?: string): Form | undefined => {
  const { data: folders } = useFolders();
  return useMemo(
    () => (folders ?? []).flatMap((folder) => folder.forms).find((form) => form.id === formId),
    [folders, formId],
  );
};
