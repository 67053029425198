import { useContext } from "react";
import { Capacitor } from "@capacitor/core";
import { BarcodeScanner, ScanErrorEvent } from "@capacitor-mlkit/barcode-scanning";
import { BarcodeScannerContext } from "../context/BarcodeScannerContext";
import useDeviceInfo from "./useDeviceInfo";
import { InsufficientPermissionError } from "./useCamera";
import { ensureSdk } from "../utils/barcodeScannerUtil";
import logger from "../utils/logger";

export type BarcodeScannerDefinition = {
  isScanning: boolean;
  isScannerInstalling: boolean;
  isScanSupported: boolean;
  startScan: () => Promise<string>;
  stopScan: () => Promise<void>;
};

const isPermissionDenied = async (): Promise<boolean> => {
  const permissionStatus = await BarcodeScanner.checkPermissions();
  return permissionStatus.camera === "denied";
};

const useBarcodeScanner = (): BarcodeScannerDefinition => {
  const { isScanning, setIsScanning, isScannerInstalling, setIsScannerInstalling } = useContext(BarcodeScannerContext);
  const { platform } = useDeviceInfo();
  const isScanSupported = Capacitor.isNativePlatform();

  const stopScan = async (): Promise<void> => {
    setIsScanning(false);
    document.querySelector("body")?.classList.remove("barcode-scanner-active");
    if (isScanSupported) {
      await BarcodeScanner.stopScan();
    }
  };

  const startScan = async (): Promise<string> => {
    try {
      const result = await BarcodeScanner.scan();

      if (result.barcodes.length > 1) {
        logger.warn("Barcode scanner has multiple entries while only using the first");
      }

      return result.barcodes.length > 0 ? result.barcodes[0].displayValue : "";
    } catch (e) {
      // Ignore user cancel events
    } finally {
      setIsScanning(false);
      document.querySelector("body")?.classList.remove("barcode-scanner-active");
    }
    return "";
  };

  return {
    isScanning,
    isScannerInstalling,
    isScanSupported,
    startScan: async (): Promise<string> => {
      if (await isPermissionDenied()) {
        throw new InsufficientPermissionError();
      }

      BarcodeScanner.addListener("scanError", (e: ScanErrorEvent): void => {
        stopScan();
        throw new Error(e.message);
      });

      await ensureSdk(platform, isScannerInstalling, setIsScannerInstalling);

      return startScan();
    },
    stopScan,
  };
};

export default useBarcodeScanner;
