export type DataSourceEntry = {
  dataSourceId: string;
  id: string;
  data: Record<string, string>;
  version: string;
};

export type DataSource = {
  id: string;
  entries: DataSourceEntry[];
  fallback: boolean;
};

export type DataSourceMeta = {
  id: string;
  customerId: number;
  version: string;
  updatedAt: string;
};

export class DataSourceNotFoundError extends Error {}
