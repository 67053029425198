import { FC, MouseEventHandler } from "react";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { Icon, IconName } from "../Icon/Icon";
import { Text } from "../Text/Text";
import { IconButton } from "../IconButton/IconButton";
import { TextButton } from "../TextButton/TextButton";

export type ToastProps = {
  className?: string;
  message: string;
  icon?: IconName;
  button?: ToastIconButtonProps | ToastTextButtonProps;
  show?: boolean;
};

export type ToastTextButtonProps = {
  type: "text";
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  navigateTo?: string;
};

export type ToastIconButtonProps = {
  type: "icon";
  icon: IconName;
  onClick: MouseEventHandler<HTMLButtonElement>;
  navigateTo?: string;
};

export const Toast: FC<ToastProps> = ({ className, icon, message, button, show = true }) => {
  const classes = classNames(
    "flex max-h-18 min-w-0 items-center justify-between gap-x-2 rounded-lg bg-gray-700 px-4 shadow-xl",
    className,
  );

  return (
    <Transition
      show={show}
      appear
      enter="transform transition duration-300"
      enterFrom="opacity-0 scale-50"
      enterTo="opacity-100 scale-100"
      leave="transform transition duration-300"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-50"
    >
      <div className={classes}>
        <div className="flex min-w-0 flex-1 items-center gap-x-2 overflow-hidden py-4">
          {icon && <Icon name={icon} className="size-5 shrink-0 text-white" />}
          <Text color="white" className="line-clamp-2">
            {message}
          </Text>
        </div>
        {button && (
          <div className="flex h-14 items-center overflow-hidden border-l border-gray-500 py-2 pl-2">
            {button?.type === "icon" && (
              <IconButton icon={button.icon} onClick={button.onClick} size="md" variant="transparentWhite" />
            )}
            {button?.type === "text" && (
              <TextButton label={button.label} onClick={button.onClick} size="md" variant="transparentWhite" />
            )}
          </div>
        )}
      </div>
    </Transition>
  );
};
