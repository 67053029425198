import uuidv4 from "./uuid";
import { CURRENT_COMMIT } from "./versionUtil";
import { getCurrentAppInfo } from "./deviceUtil";

export const generateRequestId = async (): Promise<string> => {
  const info = await getCurrentAppInfo();

  const appVersion = info.version ?? process.env.VITE_VERSION ?? "no_ver";
  const build = CURRENT_COMMIT || "no_hash";
  const type = info.platform;

  // Example:
  // Version 7.0.0, last git commit hash ending in abc123, in the iOS app
  // 7.0.0-abc123-ios-1234567890abcdef
  return `${appVersion}-${build}-${type}-${uuidv4().replaceAll("-", "")}`;
};
