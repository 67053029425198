import { FC, forwardRef, ImgHTMLAttributes, Ref, useEffect, useState } from "react";
import classNames from "classnames";
import useOnlineStatus from "../hooks/useOnlineStatus";

type Props = {
  fallbackSrc?: string;
  noInternetSrc?: string;
  fallbackClassName?: string;
  ref?: Ref<HTMLImageElement>;
} & ImgHTMLAttributes<never>;

// eslint-disable-next-line react/display-name
const Img: FC<Props> = forwardRef<HTMLImageElement, Props>(
  (
    {
      fallbackSrc = "/assets/image_error.svg",
      noInternetSrc = "/assets/no-internet.svg",
      src,
      className,
      fallbackClassName,
      ...props
    },
    ref,
  ) => {
    const [imgSrc, setImgSrc] = useState<string | undefined>(src);
    const { isOnline } = useOnlineStatus();
    useEffect(() => setImgSrc(src), [src]);
    const onError = (): void => (isOnline ? setImgSrc(fallbackSrc) : setImgSrc(noInternetSrc));
    const isFallback = !imgSrc || imgSrc === fallbackSrc || imgSrc === noInternetSrc;

    const classes = classNames(className, isFallback && fallbackClassName);

    return (
      <img src={imgSrc || fallbackSrc} alt={props.alt} onError={onError} ref={ref} className={classes} {...props} />
    );
  },
) as FC<Props>;

export default Img;
