import { useMemo, useState } from "react";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import axios, { AxiosResponse } from "axios";
import { debounce } from "lodash-es";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useDrawer from "../../hooks/useDrawer";
import logger from "../../utils/logger";
import { Label } from "../../storybook/components/Label/Label";
import { WidgetContentButton } from "../../storybook/components/WidgetContentButton/WidgetContentButton";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Drawer } from "../../storybook/components/Drawer/Drawer";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import { NumberInput } from "../../storybook/components/NumberInput/NumberInput";
import { Feedback } from "../../storybook/components/Feedback/Feedback";
import WidgetContainer from "../WidgetContainer";
import { nowToISO } from "../../utils/dateUtil";
import { useFocusId } from "../../hooks/useFocusId";

export type WidgetPostcodeProperties = {
  required: boolean;
  label_text: string;
};

export type PostcodeResult = {
  postcode: string;
  houseNumber?: number;
  addition: string;
  street: string;
  city: string;
  validated: boolean;
  validatedAt?: string;
};

const CHECK_POSTCODE_DEBOUNCE = 1000;
const POSTCODE_REGEX = /^(\d{4}[A-Z]{2})$/;
const POSTCODE_API_KEY = "o4jv6PbgK85w0rGRRqbsvZqSpCvESUJ1m5tmPGP8";

const WidgetPostcode: Widget<WidgetPostcodeProperties, WidgetResult<PostcodeResult>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();
  const { rawValue } = fieldState.value;
  const [drawerOpen, setDrawerOpen] = useDrawer(fieldState.uid);
  const [prefilled, setPrefilled] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const onCloseFocusId = useFocusId(fieldState);

  const defaultValues = {
    postcode: rawValue?.postcode ?? "",
    houseNumber: rawValue?.houseNumber ?? undefined,
    addition: rawValue?.addition ?? "",
    street: rawValue?.street ?? "",
    city: rawValue?.city ?? "",
    validated: rawValue?.validated ?? false,
    validatedAt: rawValue?.validatedAt,
  };
  const validationSchema = yup.object({
    postcode: yup
      .string()
      .required(t("VALIDATION_REQUIRED"))
      .matches(POSTCODE_REGEX, t("VALIDATION_ADDRESS_FORMAT_INVALID")),
    houseNumber: yup.number().typeError(t("VALIDATION_TYPE_NUMBER")).required(t("VALIDATION_REQUIRED")),
    street: yup.string().required(t("VALIDATION_REQUIRED")),
    city: yup.string().required(t("VALIDATION_REQUIRED")),
  }) as ObjectSchema<PostcodeResult>;

  const {
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    reset,
    setValue,
    trigger,
    setError,
  } = useForm<PostcodeResult>({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "all",
  });

  const isWidgetRequired = fieldState.properties.required;

  const switchDrawerState = (): void => {
    setDrawerOpen(!drawerOpen);
    if (!drawerOpen) {
      reset(defaultValues);
    }
  };

  const getAddressByAddition = (response: AxiosResponse<any>, newAddition: string): any =>
    response.data._embedded.addresses.find((item: any) => item.addition === newAddition || item.letter === newAddition);

  const checkInput = (postcode: string, houseNumber: number | undefined, addition: string): void => {
    const isValid = POSTCODE_REGEX.test(postcode) && Number(houseNumber);
    if (!isValid) {
      return;
    }
    setIsVerifying(true);
    verifyPostcode(postcode, houseNumber, addition)?.catch((err) => logger.error("Couldn't verify postcode", err));
  };

  const verifyPostcode = useMemo(
    () =>
      debounce(async (postcode, houseNumber, addition) => {
        if (!POSTCODE_REGEX.test(postcode) && Number(houseNumber)) {
          return; // Don't even bother sending the request
        }
        try {
          const response = await axios.get(
            `https://api.postcodeapi.nu/v2/addresses/?postcode=${postcode}&number=${houseNumber}`,
            { headers: { "X-Api-Key": POSTCODE_API_KEY } },
          );
          const result = addition ? getAddressByAddition(response, addition) : response.data._embedded.addresses[0];
          if (result) {
            setValue("street", result.street);
            setValue("city", result.city.label);
            setPrefilled(true);
            await trigger();
          } else {
            setError("street", { message: t("VERIFICATION_ADDRESS_NOT_FOUND") });
            setError("city", { message: t("VERIFICATION_ADDRESS_NOT_FOUND") });
            setPrefilled(false);
          }
        } catch (e) {
          setError("street", { message: t("VERIFICATION_ADDRESS_ERROR") });
          setError("city", { message: t("VERIFICATION_ADDRESS_ERROR") });
          setPrefilled(false);
        } finally {
          setIsVerifying(false);
        }
      }, CHECK_POSTCODE_DEBOUNCE),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const saveData = (values: PostcodeResult): void => {
    if (isVerifying) {
      return;
    }
    setFieldState({
      ...values,
      validated: prefilled,
      validatedAt: prefilled ? nowToISO() : undefined,
    });
    switchDrawerState();
  };

  const onSubmit = (values: PostcodeResult): void => {
    saveData(values);
  };

  const resetDataChanges = (): void => {
    reset(defaultValues);
    switchDrawerState();
  };

  const resetWidget = (): void => {
    setFieldState(undefined);
    reset(defaultValues);
  };

  const buttonLabel = !readOnly ? t("ADD_POSTCODE") : t("NO_POSTCODE_ADDED");

  return (
    <WidgetContainer fieldState={fieldState} name="POSTCODE_FIELD">
      <Label
        id={fieldState.uniqueFieldId}
        label={fieldState.properties.label_text}
        required={isWidgetRequired}
        showClearBtn={!!rawValue && !readOnly}
        onClear={resetWidget}
        clearLabel={t("CLEAR")}
      />
      {rawValue && !isAddressEmpty(rawValue) ? (
        <WidgetContentButton
          id={onCloseFocusId}
          items={[{ label: t("EDIT"), onClick: switchDrawerState }]}
          disabled={readOnly}
        >
          {`${rawValue?.street} ${rawValue?.houseNumber}${
            rawValue?.addition ? `${rawValue?.addition}` : ""
          }, ${rawValue?.postcode} ${rawValue?.city}`}
        </WidgetContentButton>
      ) : (
        <IconAndTextButton disabled={readOnly} label={buttonLabel} icon="HomeIcon" onClick={switchDrawerState} block />
      )}
      <Drawer
        onCloseFocusId={onCloseFocusId}
        open={drawerOpen}
        header={{
          kind: "simple",
          title: t("ADD_ADDRESS"),
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: resetDataChanges,
          },
        }}
        footer={{
          kind: "default",
          primaryButton: {
            label: t("SAVE"),
            disabled: isVerifying,
            onClick: handleSubmit(onSubmit),
          },
        }}
        onClose={resetDataChanges}
      >
        <form>
          <div className="space-y-4 pb-6 pt-4">
            <Controller
              name="postcode"
              control={control}
              render={({ field: f }) => (
                <TextInput
                  name="postcode"
                  type="text"
                  required
                  label={t("POSTCODE")}
                  value={f.value}
                  onChange={(e) => {
                    setValue("postcode", e.target.value.toUpperCase());
                    checkInput(e.target.value.toUpperCase(), getValues("houseNumber"), getValues("addition"));
                  }}
                  onBlur={f.onBlur}
                  errorMessage={errors.postcode?.message}
                />
              )}
            />
            <Controller
              name="houseNumber"
              control={control}
              render={({ field: f }) => (
                <NumberInput
                  name={f.name}
                  onBlur={f.onBlur}
                  inputMode="numeric"
                  onChange={(e) => {
                    f.onChange(e.floatValue);
                    checkInput(getValues("postcode"), e.floatValue, getValues("addition"));
                  }}
                  value={f.value || ""}
                  label={t("HOUSE_NUMBER")}
                  required
                  errorMessage={errors.houseNumber?.message}
                />
              )}
            />
            <Controller
              name="addition"
              control={control}
              render={({ field: f }) => (
                <TextInput
                  name={f.name}
                  onBlur={f.onBlur}
                  onChange={(e) => {
                    f.onChange(e);
                    checkInput(getValues("postcode"), getValues("houseNumber"), e.target.value);
                  }}
                  value={f.value}
                  type="text"
                  label={t("ADDITION")}
                />
              )}
            />
            <Controller
              name="street"
              control={control}
              render={({ field: f }) => (
                <TextInput
                  name={f.name}
                  onBlur={f.onBlur}
                  onChange={f.onChange}
                  value={f.value}
                  required
                  disabled={prefilled}
                  type="text"
                  label={t("STREET")}
                  errorMessage={errors.street?.message}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({ field: f }) => (
                <TextInput
                  name={f.name}
                  onBlur={f.onBlur}
                  onChange={f.onChange}
                  value={f.value}
                  disabled={prefilled}
                  required
                  type="text"
                  label={t("CITY")}
                  errorMessage={errors.city?.message}
                />
              )}
            />
          </div>
        </form>
      </Drawer>
      {fieldState.error && <Feedback status="error" message={fieldState.error} />}
    </WidgetContainer>
  );
};

const isAddressEmpty = (postcodeResult: PostcodeResult): boolean =>
  !postcodeResult.postcode &&
  !postcodeResult.houseNumber &&
  !postcodeResult.addition &&
  !postcodeResult.street &&
  !postcodeResult.city;

WidgetPostcode.defaultValue = (_properties, defaultMeta: any): WidgetResult<PostcodeResult> => ({
  type: "object",
  meta: {
    widget: "postcode",
    ...defaultMeta,
  },
});

WidgetPostcode.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && (!val || isAddressEmpty(val))) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetPostcode;
