import { ChangeEvent, FC, FocusEvent, PropsWithChildren } from "react";
import classNames from "classnames";
import { Icon } from "../Icon/Icon";
import { Feedback } from "../Feedback/Feedback";
import { noop } from "../../../utils/noop";

export type CheckboxProps = {
  className?: string;
  name: string;
  selected?: boolean;
  onChange?: (e: ChangeEvent<any>) => void;
  onBlur?: (e: FocusEvent<any>) => void;
  errorMessage?: string;
  disabled?: boolean;
  required?: boolean;
} & PropsWithChildren;

export const Checkbox: FC<CheckboxProps> = ({
  selected = false,
  className,
  errorMessage = "",
  disabled = false,
  required = false,
  onChange = noop,
  onBlur = noop,
  children,
  name,
}) => {
  const classes = classNames("relative -mx-2 mt-1", className);
  const labelClasses = classNames(
    "flex cursor-pointer select-none flex-col rounded px-2 py-3 text-left text-sm peer-focus-visible:ring peer-disabled:cursor-not-allowed",
    !disabled && "pointer:hover:bg-brand-50 active:!bg-brand-100",
  );
  const checkboxClasses = classNames(
    "flex size-6 shrink-0 items-center justify-center rounded border-1.5",
    disabled && {
      "border-gray-500 bg-gray-500 text-white": selected,
      "border-gray-300 bg-gray-100 text-transparent": !selected,
    },
    !disabled &&
      errorMessage && {
        "border-red-600 bg-red-600 text-white": selected,
        "border-red-600 bg-red-50 text-transparent": !selected,
      },
    !disabled &&
      !errorMessage && {
        "pointer:peer-focus-visible:ring": true,
        "bg-brand-500 text-white border-brand-500": selected,
        "bg-white text-transparent": !selected,
      },
  );

  return (
    <div className={classes}>
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={selected}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        className="peer absolute size-0 cursor-pointer opacity-0 outline-none disabled:cursor-not-allowed"
      />
      <label htmlFor={name} className={labelClasses}>
        <div className="flex">
          <span className={checkboxClasses}>
            <Icon name="CheckIcon" />
          </span>
          <span className="my-auto ml-2 overflow-hidden break-words text-gray-700">
            {children}
            {required && <span className="ml-1 align-top text-red-600">*</span>}
          </span>
        </div>
      </label>
      {errorMessage && <Feedback status="error" message={errorMessage} />}
    </div>
  );
};
