import * as Sentry from "@sentry/react";
import { CaptureContext } from "@sentry/types";

const logger = {
  log: console.log,
  error: (message: string, error?: unknown, captureContext?: CaptureContext): void => {
    console.error(message, error);
    Sentry.withScope((scope) => {
      // All errors with the same message should be grouped together under one issue in Sentry
      // https://docs.sentry.io/platforms/javascript/guides/capacitor/enriching-events/fingerprinting/#group-errors-more-aggressively
      scope.setFingerprint([message]);
      Sentry.captureException(new Error(message, { cause: error }), captureContext);
    });
  },
  debug: console.debug,
  warn: console.warn,
  dir: console.dir,
};

export default logger;
