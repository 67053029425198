import { useEffect, useMemo, useRef, useState } from "react";

/**
 * Hook to run an async effect on mount and another on unmount.
 * https://marmelab.com/blog/2023/01/11/use-async-effect-react.html
 */

export const useAsyncEffect = (
  mountCallback: () => Promise<any>,
  unmountCallback: () => Promise<any>,
  deps: any[] = [],
): UseAsyncEffectResult => {
  const isMounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(undefined);
  const [result, setResult] = useState<any>();

  useEffect(() => {
    isMounted.current = true;
    return (): void => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let ignore = false;
    let mountSucceeded = false;

    (async (): Promise<void> => {
      await Promise.resolve(); // wait for the initial cleanup in Strict mode - avoids double mutation
      if (!isMounted.current || ignore) {
        return;
      }
      setIsLoading(true);
      try {
        const r2 = await mountCallback();
        mountSucceeded = true;
        if (isMounted.current && !ignore) {
          setError(undefined);
          setResult(r2);
          setIsLoading(false);
        } else {
          // Component was unmounted before the mount callback returned, cancel it
          await unmountCallback();
        }
      } catch (e) {
        if (!isMounted.current) {
          return;
        }
        setError(e);
        setIsLoading(false);
      }
    })();

    return (): void => {
      ignore = true;
      if (mountSucceeded) {
        unmountCallback()
          .then(() => {
            if (!isMounted.current) {
              return;
            }
            setResult(undefined);
          })
          .catch((e: unknown) => {
            if (!isMounted.current) {
              return;
            }
            setError(e);
          });
      }
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(() => ({ result, error, isLoading }), [result, error, isLoading]);
};

export type UseAsyncEffectResult = {
  result: any;
  error: any;
  isLoading: boolean;
};
