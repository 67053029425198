import { FC, ImgHTMLAttributes } from "react";
import { Theme, Themes } from "../storybook/themes";

type SentIconProps = {
  theme: Theme;
} & ImgHTMLAttributes<never>;

const SentIcon: FC<SentIconProps> = ({ theme = Themes.Default, ...props }) => (
  <svg width="211" height="195" viewBox="0 0 211 195" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="98.2783" cy="97.5" r="97.5" className="fill-gray-50" />
    <path
      className={theme.fill[500]}
      d="M36.0928 110.5L210.221 44L153.081 140.405L110.914 129.557L85.2465 151.252L72.5657 119.779L36.0928 110.5Z"
    />
    <path className={theme.fill[300]} d="M85.3987 151.099L88.1487 124.209L110.913 129.71L85.3987 151.099Z" />
    <path className={theme.fill[200]} d="M72.4129 119.779L35.5928 110.307L210.068 44L72.4129 119.779Z" />
    <path className={theme.fill[200]} d="M153.387 140.405L210.222 44L87.9971 124.21L153.387 140.405Z" />
  </svg>
);

export default SentIcon;
