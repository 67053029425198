/* eslint-disable no-param-reassign */
import { Field, UploadStatus } from "../../../types/Field";
import { Submission } from "../../../types/Submission";
import { compress, decompress } from "../../../utils/compressUtil";
import logger from "../../../utils/logger";

type FileResultBeforeMigration = {
  id: string;
  extension?: string;
  name?: string;
  remoteId?: string;
  uploadStatus?: UploadStatus;
};

export const migrationWithOrder = (oldDoc: Field): Field => {
  oldDoc.order = -1;
  return oldDoc;
};

export const migrationRxdb14 = <T>(oldDoc: T): T => oldDoc;

export const migrationCompressedFields = (oldDoc: Field): Field => {
  oldDoc.compressed = false;
  return oldDoc;
};

export const migrationSubmittedAt = (oldDoc: Submission): Submission => {
  oldDoc.submittedAt = undefined;
  return oldDoc;
};

export const migrationUploadStatus = (oldDoc: Field): Field => {
  if (oldDoc.type !== "file" || !oldDoc.data) {
    return oldDoc;
  }

  try {
    const isCompressed = oldDoc.compressed && typeof oldDoc.data === "string" && oldDoc.data.startsWith("H4s");
    const data = (isCompressed ? decompress(oldDoc.data as string) : oldDoc.data) as FileResultBeforeMigration;

    oldDoc.compressed = isCompressed; // corrects 'false-positive', when set to compressed, but not actually compressed
    oldDoc.uploadStatus = data.uploadStatus;

    delete data.uploadStatus;
    oldDoc.data = isCompressed ? compress(data) : data;
  } catch (e) {
    logger.error("Could not migrate field", e, { extra: { field: oldDoc } });
  }

  return oldDoc;
};
