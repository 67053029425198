import { IEvent } from "fabric/fabric-impl";
import { fabric } from "fabric";
import { clamp } from "lodash-es";
import { DrawingResult } from "../components/widgets/WidgetDrawing";

export type FabricMode = "draw" | "drag" | "line" | "arrow" | "navigate";

export type FabricProps = {
  lastPosX: number;
  lastPosY: number;
  color: string;
  isDown: boolean;
  isDragging: boolean;
  activeMode: FabricMode;
  fabricObject?: fabric.Line;
};

export const generateImage = (widgetData: DrawingResult): Promise<string> => {
  // @ts-expect-error - This is a custom shape
  if (!fabric.LineArrow) {
    // Load custom shapes here
    setupArrowShape();
  }
  if (!widgetData.dimensions || !widgetData.canvas) {
    return Promise.reject(new Error("Unable to generate image because dimensions or canvas is missing"));
  }
  const { width, height } = widgetData.dimensions;
  const canvas = new fabric.StaticCanvas(null, { width, height });
  return new Promise((resolve) => {
    canvas.loadFromJSON(widgetData.canvas, () => {
      resolve(canvas.toDataURL());
    });
  });
};

export const updateBackgroundUrl = (widgetData: DrawingResult, backgroundUrl: string): DrawingResult => ({
  ...widgetData,
  canvas: {
    ...widgetData.canvas,
    backgroundImage: { ...widgetData.canvas.backgroundImage, src: backgroundUrl },
  },
});

export const backgroundUrlUpdateNeeded = (fileSrc?: string, widgetData?: DrawingResult): boolean => {
  if (!widgetData?.canvas || !fileSrc) {
    return false;
  }
  const fileSrcUrl = new URL(fileSrc);
  const parsedBackgroundUrlCanvas = new URL(widgetData.canvas.backgroundImage.src);
  return fileSrcUrl.host !== parsedBackgroundUrlCanvas.host && fileSrcUrl.protocol.startsWith("http");
};

export const enforceBoundaries = (canvas: fabric.Canvas): void => {
  const currentZoom = canvas.getZoom();
  const size = canvas.getHeight();
  const vpt = canvas.viewportTransform;
  if (!vpt) {
    return;
  }
  if (currentZoom < size / size) {
    vpt[4] = size / 2 - (size * currentZoom) / 2;
    vpt[5] = size / 2 - (size * currentZoom) / 2;
  } else {
    if (vpt[4] >= 0) {
      vpt[4] = 0;
    } else if (vpt[4] < size - size * currentZoom) {
      vpt[4] = size - size * currentZoom;
    }
    if (vpt[5] >= 0) {
      vpt[5] = 0;
    } else if (vpt[5] < size - size * currentZoom) {
      vpt[5] = size - size * currentZoom;
    }
  }
};

export const placeObjectMouseDown = (canvas: fabric.Canvas, fabricProps: FabricProps, o: IEvent<MouseEvent>): void => {
  fabricProps.isDown = true;

  canvas.controlsAboveOverlay = true;
  const pointer = canvas.getPointer(o.e);
  const points = [pointer.x, pointer.y, pointer.x, pointer.y];
  if (fabricProps.activeMode === "line") {
    const newLine = new fabric.Line(points, {
      strokeWidth: 5,
      fill: fabricProps.color,
      stroke: fabricProps.color,
      originX: "center",
      originY: "center",
      selectable: false,
    });
    canvas.add(newLine);

    fabricProps.fabricObject = newLine;
  }
  if (fabricProps.activeMode === "arrow") {
    // @ts-expect-error - This is a custom shape
    const newArrow = new fabric.LineArrow(points, {
      strokeWidth: 5,
      fill: fabricProps.color,
      stroke: fabricProps.color,
      originX: "center",
      originY: "center",
      selectable: false,
    });
    canvas.add(newArrow);

    fabricProps.fabricObject = newArrow;
  }
};

export const placeObjectMouseMove = (canvas: fabric.Canvas, fabricProps: FabricProps, o: IEvent<MouseEvent>): void => {
  if (!fabricProps?.isDown) {
    return;
  }
  const pointer = canvas.getPointer(o.e);
  fabricProps?.fabricObject?.set({ x2: pointer.x, y2: pointer.y });
};

export const navigateMouseDown = (canvas: fabric.Canvas, fabricProps: FabricProps, o: IEvent<MouseEvent>): void => {
  fabricProps.isDragging = true;

  // @ts-ignore
  const lastPosX = o.e.clientX !== undefined ? o.e.clientX : o.e.touches[0].clientX;
  // @ts-ignore
  const lastPosY = o.e.clientY !== undefined ? o.e.clientY : o.e.touches[0].clientY;

  fabricProps.lastPosX = lastPosX;

  fabricProps.lastPosY = lastPosY;
  enforceBoundaries(canvas);
};

export const navigateMouseMove = (canvas: fabric.Canvas, fabricProps: FabricProps, o: IEvent<MouseEvent>): void => {
  if (!canvas?.viewportTransform) {
    return;
  }
  const { e } = o;
  // @ts-ignore
  const x = e.clientX !== undefined ? e.clientX : e.touches[0].clientX;
  // @ts-ignore
  const y = e.clientY !== undefined ? e.clientY : e.touches[0].clientY;
  const { lastPosX, lastPosY } = fabricProps;

  canvas.viewportTransform[4] += x - lastPosX;

  canvas.viewportTransform[5] += y - lastPosY;
  canvas.renderAll();

  fabricProps.lastPosX = x;

  fabricProps.lastPosY = y;
  enforceBoundaries(canvas);
};

export const disableSelection = (canvas: fabric.Canvas): void => {
  canvas.selection = false;
  canvas.forEachObject((o) => {
    o.selectable = false;
  });
};

export const enableSelection = (canvas: fabric.Canvas): void => {
  canvas.selection = false;
  canvas.forEachObject((o) => {
    o.selectable = true;
  });
};

export const changeColor = (canvas: fabric.Canvas, fabricProps: FabricProps, color: string): void => {
  fabricProps.color = color;

  canvas.freeDrawingBrush.color = color;
};

export const initializeEvents = (
  canvas: fabric.Canvas,
  fabricProps: FabricProps,
  setZoom: (value: number) => void,
): void => {
  canvas.allowTouchScrolling = true;
  canvas.on("object:selected", (o) => {
    if (o.target) {
      o.target.cornerSize = 20;
    }
  });

  canvas.on("mouse:down", (o) => {
    const { activeMode: mode } = fabricProps;
    if (mode === "navigate") {
      navigateMouseDown(canvas, fabricProps, o);
    } else if (mode === "line" || mode === "arrow") {
      placeObjectMouseDown(canvas, fabricProps, o);
    }
  });

  canvas.on("mouse:move", (o) => {
    const { activeMode: mode, isDragging } = fabricProps;
    if (mode === "navigate" && isDragging) {
      navigateMouseMove(canvas, fabricProps, o);
    } else if (mode === "line" || mode === "arrow") {
      placeObjectMouseMove(canvas, fabricProps, o);
    }
    canvas.renderAll();
  });

  canvas.on("mouse:up", () => {
    if (fabricProps) {
      fabricProps.isDragging = false;
      fabricProps.fabricObject?.setCoords();

      fabricProps.isDown = false;
    }
  });

  canvas.on("mouse:wheel", (o) => {
    const delta = o.e.deltaY;
    let currentZoom = canvas.getZoom();
    currentZoom += delta / 200;
    currentZoom = clamp(currentZoom, 1, 5);

    canvas.zoomToPoint({ x: o.e.offsetX, y: o.e.offsetY }, currentZoom);
    setZoom(currentZoom);
    enforceBoundaries(canvas);
    o.e.preventDefault();
    o.e.stopPropagation();
  });
};

export const setBackgroundImage = async (canvas: fabric.Canvas, backgroundImageUrl: string): Promise<void> => {
  if (!backgroundImageUrl) {
    return;
  }
  let top = 0;
  let left = 0;
  let originX = "left";
  let originY = "top";
  fabric.Image.fromURL(
    backgroundImageUrl,
    (image) => {
      if (!image?.width || !image?.height || !canvas.getContext()) {
        return;
      }
      if (image.width > image.height) {
        image.scaleToWidth(canvas.getWidth());
        top = canvas.getHeight() / 2;
        originY = "center";
      } else {
        image.scaleToHeight(canvas.getHeight());
        left = canvas.getWidth() / 2;
        originX = "center";
      }
      canvas.setBackgroundImage(
        image,
        () => {
          image.center();
          canvas.renderAll();
        },
        { top, left, originX, originY },
      );
    },
    {
      crossOrigin: "anonymous",
    },
  );
};

// Only way to extend shapes in FabricJS. Because this isn't compatible with typing, we have to have some tsignores.
export const setupArrowShape = (): void => {
  const LineArrow = fabric.util.createClass(fabric.Line, {
    type: "LineArrow",

    initialize(element: any, options: any) {
      // eslint-disable-next-line no-param-reassign
      options || (options = {});
      this.callSuper("initialize", element, options);
    },

    toObject() {
      return fabric.util.object.extend(this.callSuper("toObject"), undefined);
    },

    _render(ctx: {
      save: () => void;
      translate: (arg0: number, arg1: number) => void;
      rotate: (arg0: number) => void;
      beginPath: () => void;
      moveTo: (arg0: number, arg1: number) => void;
      lineTo: (arg0: number, arg1: number) => void;
      closePath: () => void;
      fillStyle: any;
      fill: () => void;
      restore: () => void;
    }) {
      this.callSuper("_render", ctx);

      // do not render if width/height are zeros or object is not visible
      if (this.width === 0 || this.height === 0 || !this.visible) {
        return;
      }

      ctx.save();

      const xDiff = this.x2 - this.x1;
      const yDiff = this.y2 - this.y1;
      const angle = Math.atan2(yDiff, xDiff);
      ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
      ctx.rotate(angle);
      ctx.beginPath();
      // move 10px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
      ctx.moveTo(10, 0);
      ctx.lineTo(-20, 15);
      ctx.lineTo(-20, -15);
      ctx.closePath();
      ctx.fillStyle = this.stroke;
      ctx.fill();

      ctx.restore();
    },
  });

  LineArrow.fromObject = (object: { x1: any; y1: any; x2: any; y2: any }, callback: (arg0: any) => any): void => {
    callback && callback(new LineArrow([object.x1, object.y1, object.x2, object.y2], object));
  };

  LineArrow.async = true;
  // @ts-expect-error - This is a custom shape
  fabric.LineArrow = LineArrow;
};
