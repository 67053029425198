import { FC } from "react";
import { Button } from "../Button/Button";
import { TextButtonProps } from "../TextButton/TextButton";
import { IconName } from "../Icon/Icon";

export type IconAndTextButtonProps = {
  icon: IconName;
  iconAlign?: "left" | "right";
  iconType?: "solid" | "outline";
} & TextButtonProps;

export const IconAndTextButton: FC<IconAndTextButtonProps> = (props) => (
  <Button iconType="solid" block={false} iconAlign="left" {...props} />
);
