import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { pick, startCase } from "lodash-es";
import { useTranslation } from "react-i18next";
import { DataSourceEntry } from "../../../types/Datasource";
import useDatasource from "../../../hooks/useDatasource";
import useDrawer from "../../../hooks/useDrawer";
import { DatasourceColor } from "../WidgetSearch";
import { getSearchEntryHumanText } from "../../../utils/stringUtil";
import SearchValue from "./SearchValue";
import EmptyContent from "../../EmptyContent";
import DatasourceStatusBanner from "./DatasourceStatusBanner";
import { Text } from "../../../storybook/components/Text/Text";
import { Spinner } from "../../../storybook/components/Spinner/Spinner";
import { Indicator } from "../../../storybook/components/Indicator/Indicator";
import { IconButton } from "../../../storybook/components/IconButton/IconButton";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";
import useMiniSearchWorker from "../../../hooks/useMiniSearchWorker";
import { DatasourceOptions, EntryData } from "../../../workers/dataSourceMiniSearchWorker";

type SearchContentProps = {
  dataSourceId: string;
  query: string;
  entryFields: string[];
  colors?: DatasourceColor[];
  filterQueries?: string[];
  isExactQuery: boolean;
  onChange: (value: DataSourceEntry) => void;
  onInit: (value: boolean) => void;
};

const ID_KEY = "id";

const SearchContent: FC<SearchContentProps> = ({
  dataSourceId,
  query,
  entryFields,
  colors,
  filterQueries,
  isExactQuery,
  onChange,
  onInit,
}) => {
  const { t } = useTranslation();
  const { data: datasource, isLoading, isError } = useDatasource(dataSourceId);
  const [activeOpen, setActiveOpen] = useDrawer("search-active");
  const [activeItem, setActiveItem] = useState<DataSourceEntry | undefined>();
  const virtuoso = useRef<VirtuosoHandle>(null);
  const options: DatasourceOptions = useMemo(
    () => ({
      type: "datasource",
      matchAllOnEmptyQuery: true,
      miniSearchOptions: {
        fields: entryFields,
      },
    }),
    [entryFields],
  );
  const getId = useCallback((entry: EntryData) => entry[ID_KEY] || "", []);
  const entries = useMemo(
    () => datasource?.entries.map((entry) => pick(entry.data, ...entryFields, ID_KEY)),
    [datasource?.entries, entryFields],
  );
  const {
    result: hits,
    isInitializing,
    isError: isSearchError,
  } = useMiniSearchWorker<EntryData>(query, true, options, getId, isExactQuery, entries, filterQueries);

  useEffect(() => virtuoso.current?.scrollToIndex(0), [hits]);

  useEffect(() => onInit(!isLoading && !isError && !isInitializing), [isLoading, isError, isInitializing]);

  if (isError) {
    return (
      <EmptyContent
        imgSrc="assets/empty.svg"
        title={t("DATASOURCE_NOT_EXIST_OR_NOT_AVAILABLE_TITLE")}
        description={t("DATASOURCE_NOT_EXIST_OR_NOT_AVAILABLE_DESCRIPTION")}
      />
    );
  }

  if (isLoading || isInitializing || !filterQueries) {
    return <Spinner className="mx-auto mt-4" />;
  }

  if (isSearchError) {
    return (
      <EmptyContent
        imgSrc="assets/empty.svg"
        title={t("DATASOURCE_MALFUNCTION_TITLE")}
        description={t("DATASOURCE_MALFUNCTION_DESCRIPTION")}
      />
    );
  }

  return (
    <>
      <DatasourceStatusBanner dataSourceId={dataSourceId} />
      <Virtuoso
        className="mx-4"
        ref={virtuoso}
        data={hits}
        itemContent={(_index, hit) => {
          const completeEntry = datasource.entries.find((entry) => entry.data.id === hit.item.id)!;
          const content = getSearchEntryHumanText(hit.item, entryFields);
          const indicator = colors?.find(
            (color) =>
              color.query &&
              color.color &&
              Object.values(hit.item).find(
                (column) => column?.toString()?.toLowerCase().trim() === color.query.toLowerCase().trim(),
              ),
          );
          return (
            <div className="relative flex grow items-center border-b">
              <button
                key={hit.item.id}
                className="flex w-full min-w-0 items-center rounded py-3 text-left outline-none ring-inset focus-visible:ring"
                onClick={() => onChange(completeEntry)}
              >
                <Text className="grow">{content}</Text>
                {indicator && (
                  <Indicator
                    size="lg"
                    color={indicator.color}
                    ariaLabel={t("HIGHLIGHTED_DATASOURCE_ENTRY_INDICATOR")}
                  />
                )}
              </button>
              <IconButton
                className="absolute shrink-0 ring-inset"
                aria-label={t("SEARCH_VIEW_INFO")}
                icon="InformationCircleIcon"
                iconType="outline"
                variant="transparentBrand"
                data-testid="detail-button"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveItem(completeEntry);
                  setActiveOpen(true);
                }}
              />
            </div>
          );
        }}
      />
      <Drawer
        open={activeOpen}
        header={{
          kind: "simple",
          title: getSearchEntryHumanText(activeItem ? activeItem.data : {}, entryFields),
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: () => setActiveOpen(false),
          },
        }}
        onClose={() => setActiveOpen(false)}
        footer={{
          kind: "default",
          primaryButton: { label: t("SEARCH_SELECT_ITEM"), onClick: () => onChange(activeItem!) },
        }}
      >
        {activeItem &&
          activeItem.data &&
          Object.entries(activeItem.data)
            .filter(([key]) => entryFields.includes(key))
            .map(([key, value]) => (
              <div key={key} className="flex items-center justify-between space-x-2 border-b border-gray-200 py-3">
                <Text color="medium">{startCase(key)}</Text>
                <Text>
                  <SearchValue value={value} />
                </Text>
              </div>
            ))}
      </Drawer>
    </>
  );
};

export default SearchContent;
