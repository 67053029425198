import { Duration } from "luxon";
import DOMPurify from "isomorphic-dompurify";
import { replace } from "lodash-es";
import { WidgetResult } from "../types/Field";
import { CurrencyResult, DurationResult, LocationResult } from "../types/Widget";
import { formatCurrency } from "./formatter";
import { durationToString } from "./stringUtil";
import { templateRegex, traversePath } from "./interpolateUtil";
import { DataSourceEntry } from "../types/Datasource";
import { DateTimeValue } from "../storybook/components/DateTimeInput/DateTimeInput";
import { toHumanReadableDate } from "./dateUtil";
import { PostcodeResult } from "../components/widgets/WidgetPostcode";
import { IBANResult } from "../components/widgets/WidgetIBAN";
import { SubmissionFormData } from "../components/Form";

const ALLOWED_PHOTO_RENDER = ["photo", "pin", "drawing", "signature"];

const nullishNotZeroOrFalse = (result?: WidgetResult<unknown>): boolean =>
  !result?.rawValue && result?.rawValue !== 0 && result?.rawValue !== false;

const valueOrEmpty = (nestedValue?: string): string =>
  nestedValue === undefined || nestedValue === null ? "" : nestedValue;

type TemplateOptions = { humanReadable?: boolean; includeFiles?: boolean };
export const getTemplatedContent = (
  path: string,
  result: WidgetResult<unknown>,
  options: TemplateOptions = {},
): string => {
  if (nullishNotZeroOrFalse(result)) {
    return "";
  }
  if (result.meta.widget === "search") {
    const searchResult = result as WidgetResult<DataSourceEntry>;
    const pathElements = path.split(".");
    const nestedValue = traversePath(pathElements.slice(1).join("."), searchResult.rawValue?.data ?? {});
    if (typeof nestedValue === "boolean") {
      return nestedValue ? "✓" : "✗";
    }
    return valueOrEmpty(nestedValue);
  }
  if (result.meta.widget === "iban") {
    const value = result.rawValue as IBANResult;
    return value.ibanNumber;
  }
  if (result.meta.widget === "postcode") {
    const postcode = result.rawValue as PostcodeResult;

    // Support legacy placeholders as well.
    const compatibleValue = {
      ...postcode,
      straatnaam: postcode.street,
      plaatsnaam: postcode.city,
      toevoeging: postcode.addition,
      huisnummer: postcode.houseNumber,
    };
    const pathElements = path.split(".");
    return valueOrEmpty(traversePath(pathElements.slice(1).join("."), compatibleValue ?? {}));
  }
  if (result.type === "date") {
    if (options.humanReadable) {
      // Append Z ("Zulu time" / UTC) and ignore local TZ to render as plain date
      return toHumanReadableDate(`${result.rawValue}T00:00:00Z`, false, true);
    }
    return `${result.rawValue}`;
  }
  if (result.type === "datetime") {
    const dateTime = result.rawValue as DateTimeValue;
    if (options.humanReadable) {
      // Append Z ("Zulu time" / UTC) and ignore local TZ to render as plain datetime
      return toHumanReadableDate(`${dateTime.date}T${dateTime.time}Z`, true, true);
    }
    return `${dateTime.date} ${dateTime.time}`;
  }
  if (result.type === "currency") {
    return getCurrencyContent(result as WidgetResult<CurrencyResult>);
  }
  if (result.type === "duration") {
    return getDurationContent(result as WidgetResult<DurationResult>);
  }
  if (result.type === "location") {
    const locationResult = result as WidgetResult<LocationResult>;
    if (path.split(".").length === 1) {
      return locationResult?.rawValue?.formattedValue ?? "";
    }
    const pathElements = path.split(".");
    return valueOrEmpty(traversePath(pathElements.slice(1).join("."), result.rawValue));
  }
  if (result.type === "object") {
    const pathElements = path.split(".");
    return valueOrEmpty(traversePath(pathElements.slice(1).join("."), result.rawValue ?? {}));
  }
  if (result.type === "boolean") {
    const check = result.rawValue as boolean;
    return check ? "✓" : "✗";
  }
  if (result.type === "file") {
    if (!options.includeFiles || !ALLOWED_PHOTO_RENDER.includes(result.meta.widget)) {
      return "";
    }
    // real value will be replaced by the actual file in the templateContent component
    return `FILE:${result.meta.fieldId}`;
  }

  return result.rawValue?.toString().trim() ?? "";
};

export const getSanitizedTemplatedContent = (
  template: string,
  inputData: SubmissionFormData,
  includeFiles = false,
): string => {
  const result = replace(template, templateRegex, (_match: string, path: string) => {
    const widgetResult = inputData[path.split(".")[0]];
    return getTemplatedContent(path, widgetResult, { humanReadable: true, includeFiles });
  });
  // NOTE: Extremely important to sanitize template before rendering
  // https://pragmaticwebsecurity.com/articles/spasecurity/react-xss-part2.html
  return DOMPurify.sanitize(result.trim(), {
    // Allow HTTP(S)/Capacitor protocol in sanitized html
    ALLOWED_URI_REGEXP: /^(?:(?:(?:f|ht)tps?|capacitor):|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/i,
  });
};

const getCurrencyContent = (result: WidgetResult<CurrencyResult>): string => {
  const currency = result.rawValue;
  if (!currency?.value && currency?.value !== 0) {
    return "";
  }
  return currency.currency
    ? formatCurrency(currency.value ?? 0, navigator.language, {
        currency: currency.currency,
      })
    : (currency.value?.toString() ?? "");
};

const getDurationContent = (result: WidgetResult<DurationResult>): string => {
  const duration = result.rawValue;
  if (!duration?.duration) {
    return "";
  }
  return durationToString(Duration.fromISO(duration.duration));
};
