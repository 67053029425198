import { createContext, FC, PropsWithChildren, useEffect, useState } from "react";
import * as Sentry from "@sentry/capacitor";
import { useLocalStorage } from "usehooks-ts";
import { Authorization } from "../hooks/useAuth";
import { Customer } from "../hooks/useCustomers";

export const AuthProvider: FC<PropsWithChildren<void>> = ({ children }) => {
  const [authorization, setAuthorization] = useLocalStorage<Authorization>("authorization", {});
  const [customerId, setCustomerId] = useLocalStorage<number | undefined>("customerId", undefined);
  const [customerName, setCustomerName] = useLocalStorage<string | undefined>("customerName", undefined);
  const [customers, setCustomers] = useLocalStorage<Customer[] | undefined>("customers", undefined);
  const [lastLogin, setLastLogin] = useLocalStorage<Date | undefined>("lastLogin", undefined);
  const [isLoading, setLoading] = useState(true);
  const [impersonatedUser, setImpersonatedUser] = useState<string>();
  const [directUrlAuth, setDirectUrlAuth] = useState<Authorization>();

  useEffect(() => {
    if (authorization || directUrlAuth) {
      setLoading(false);
    }
  }, [authorization, directUrlAuth]);

  useEffect(() => {
    if (authorization?.userId) {
      Sentry.setUser({
        id: authorization.userId,
        segment: authorization.type,
        ip_address: undefined,
      });
    } else {
      Sentry.getCurrentScope().setUser(null);
    }
  }, [authorization?.userId, authorization?.type]);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        authorization,
        setAuthorization,
        customerId,
        setCustomerId,
        customerName,
        setCustomerName,
        customers,
        setCustomers,
        lastLogin,
        setLastLogin,
        directUrlAuth,
        setDirectUrlAuth,
        impersonatedUser,
        setImpersonatedUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export type AuthState = {
  isLoading: boolean;
  authorization: Authorization;
  setAuthorization: (value: Authorization) => void;
  directUrlAuth?: Authorization;
  setDirectUrlAuth: (value: Authorization | undefined) => void;
  customerId?: number;
  setCustomerId: (value: number | undefined) => void;
  customerName?: string;
  setCustomerName: (value: string | undefined) => void;
  customers?: Customer[];
  setCustomers: (value: Customer[] | undefined) => void;
  lastLogin?: Date;
  setLastLogin: (value: Date) => void;
  impersonatedUser?: string;
  setImpersonatedUser: (value?: string) => void;
};

export const AuthContext = createContext<AuthState>({} as AuthState);
