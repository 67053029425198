import { FC } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import useSubmissionBadge from "../hooks/useSubmissionBadge";
import { TabBar, TabBarOption } from "../storybook/components/TabBar/TabBar";

type NavTabBarProps = {
  className?: string;
};

const NavTabBar: FC<NavTabBarProps> = ({ className }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const selected = location.pathname.split("/")[1];
  const { tasks, drafts, sending, cutOffValue } = useSubmissionBadge();

  const options: TabBarOption[] = [
    { href: "folders", icon: "DocumentIcon", label: t("MENU_NEW_FORM") },
    {
      href: "tasks",
      icon: "CheckCircleIcon",
      label: t("MENU_TASKS"),
      ...(tasks && {
        badge: { value: tasks, cutOffValue, variant: "important" },
      }),
    },
    {
      href: "drafts",
      icon: "PencilAltIcon",
      label: t("MENU_DRAFTS"),
      ...(drafts && {
        badge: { value: drafts, cutOffValue },
      }),
    },
    {
      href: "sent",
      icon: "SendIcon",
      label: t("MENU_SENT"),
      ...(sending && {
        badge: { value: sending, cutOffValue },
      }),
    },
  ];

  return <TabBar className={classNames("pb-safe", className)} selected={selected} options={options} />;
};

export default NavTabBar;
