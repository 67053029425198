import { useTranslation } from "react-i18next";
import { LocationResult, Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import WidgetLocation, { WidgetLocationProperties } from "./WidgetLocation";
import { WidgetContentButton } from "../../storybook/components/WidgetContentButton/WidgetContentButton";
import { Label } from "../../storybook/components/Label/Label";
import WidgetContainer from "../WidgetContainer";
import { FieldState } from "../../types/SubmissionState";

export type WidgetCurrentLocationProperties = {
  required: boolean;
  label_text: string;
};

const WidgetCurrentLocation: Widget<WidgetCurrentLocationProperties, WidgetResult<LocationResult>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();
  const { rawValue } = fieldState.value;

  /**
   * The Current location widget is saved as a String in the backend.
   * So we can only show the formatted string when the form is Sent/copied, as all the other data is unavailable.
   *
   * When location is available or empty, we can continue to the normal Location widget.
   */
  const showFallback = rawValue && !rawValue.coordinates && rawValue.formattedValue;
  if (showFallback) {
    return (
      <WidgetContainer fieldState={fieldState} name="LOCATION_FIELD">
        <Label
          id={fieldState.uniqueFieldId}
          label={fieldState.properties.label_text}
          required={fieldState.properties.required}
          showClearBtn={!!rawValue && !readOnly}
          onClear={() => setFieldState(undefined)}
          clearLabel={t("RESET")}
        />
        <WidgetContentButton className="mt-1 rounded-t-none" disabled={readOnly}>
          {rawValue?.formattedValue}
        </WidgetContentButton>
      </WidgetContainer>
    );
  }
  return (
    <WidgetLocation
      fieldState={fieldState as FieldState<WidgetLocationProperties, WidgetResult<LocationResult>>}
      setFieldState={setFieldState}
      readOnly={readOnly}
    />
  );
};

WidgetCurrentLocation.defaultValue = (_properties, defaultMeta: any): WidgetResult<LocationResult> => ({
  type: "location",
  meta: {
    widget: "currentLocation",
    ...defaultMeta,
  },
});

WidgetCurrentLocation.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

WidgetCurrentLocation.mapProperties = (input: WidgetCurrentLocationProperties): WidgetLocationProperties => ({
  ...input,
  initial_current_location: true,
});

export default WidgetCurrentLocation;
