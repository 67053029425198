import classNames from "classnames";
import { Spinner } from "../Spinner/Spinner";

type DefaultBadgeProps = { className?: string };
type NumberBadgeProps = {
  variant?: "default" | "important";
  value: number;
  cutOffValue?: number;
} & DefaultBadgeProps;
type IconBadgeProps = { variant: "warning" | "loading" } & DefaultBadgeProps;

export type BadgeProps = IconBadgeProps | NumberBadgeProps;

export const Badge = (props: BadgeProps): JSX.Element => {
  switch (props.variant) {
    case "warning":
    case "loading":
      return <IconBadge {...props} />;
    case undefined:
    case "default":
    case "important":
    default:
      return <NumberBadge variant="default" {...props} />;
  }
};

const NumberBadge = ({ variant, value, cutOffValue, className }: NumberBadgeProps): JSX.Element => {
  const isSingleDigit = value >= 0 && value <= 9;

  const classes = classNames(
    "inline-flex h-5 items-center justify-center rounded-full text-xs font-bold ring-2 ring-white",
    isSingleDigit ? "w-5" : "px-1.5",
    variant === "default" && "bg-gray-200 text-gray-700",
    variant === "important" && "bg-red-600 text-white",
    className,
  );

  const getValueString = (): string | number => {
    if (cutOffValue) {
      return value > cutOffValue ? `${cutOffValue}+` : value;
    }
    return value;
  };

  return <span className={classes}>{getValueString()}</span>;
};

const IconBadge = ({ variant, className }: IconBadgeProps): JSX.Element => {
  const classes = classNames(
    "inline-flex size-5 items-center justify-center rounded-full text-xs font-bold",
    variant === "loading" && "bg-gray-200 text-gray-700 ring-2 ring-white",
    variant === "warning" && "bg-white text-red-600",
    className,
  );

  return <span className={classes}>{variant === "loading" ? <Spinner className="size-4" /> : warningIconSVG}</span>;
};

// Not using the exclamation <Icon> since it has whitespace around which we can't style away and get the required size of 20x20
const warningIconSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" className="size-5" viewBox="0 0 20 20" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM11.25 15C11.25 15.6904 10.6904 16.25 10 16.25C9.30964 16.25 8.75 15.6904 8.75 15C8.75 14.3096 9.30964 13.75 10 13.75C10.6904 13.75 11.25 14.3096 11.25 15ZM10 3.75C9.30964 3.75 8.75 4.30964 8.75 5V10C8.75 10.6904 9.30964 11.25 10 11.25C10.6904 11.25 11.25 10.6904 11.25 10V5C11.25 4.30964 10.6904 3.75 10 3.75Z"
    />
  </svg>
);
