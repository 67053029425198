import { FC } from "react";
import { Button, GenericButtonProps } from "../Button/Button";

export type TextButtonProps = {
  label: string;
  block?: boolean;
  size?: "lg" | "md";
} & GenericButtonProps;

export const TextButton: FC<TextButtonProps> = (props) => <Button block={false} {...props} />;
