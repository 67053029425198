import { isNil, last } from "lodash-es";
import { deepEqual } from "fast-equals";
import { DateTime } from "luxon";
import { Field } from "../types/Field";
import { FieldProperties, FormField, FormRule, FormVersion } from "../types/FormVersion";
import { EntryFieldMap, FieldMap, FieldResult, FieldRuleResult, FormRuleContext, RuleResult } from "../types/Rules";
import { getRuleActions } from "./ruleUtil";
import { getCalculatedFieldId, getPinFormVersions, getSubformVersion } from "./formUtil";
import { getSafeNumber } from "./numberUtil";
import { UniqueFieldId } from "../types/SubmissionState";

const NESTED_WIDGETS = ["com.moreapps:detail:1", "com.moreapps:pin:1"];

export const evaluateRules = (
  submissionId: string,
  fields: Field[],
  formVersion: FormVersion,
  username: string,
): FieldResult[] => {
  const fieldRuleContext = getFormRuleContexts(submissionId, fields, formVersion);
  return getFieldActions(fieldRuleContext, fields, username)
    .map((x) => getFieldResult(x.uniqueFieldId, x.results, fields))
    .filter((x) => x?.uniqueFieldId) as FieldResult[];
};

const generateRuleMap = (
  submissionId: string,
  formFields: FormField<any>[],
  fieldsMap: Map<string, Field>,
  fieldProperties: FieldProperties,
  entryId?: string,
  parentId?: UniqueFieldId,
): Map<string, Map<string, FormRule[]>> => {
  let result = new Map<string, Map<string, FormRule[]>>();
  formFields
    .filter(({ widget }) => NESTED_WIDGETS.includes(widget))
    .forEach((formField) => {
      const fieldId = getCalculatedFieldId(formField.uid, submissionId, entryId, parentId);
      const nestedField = fieldsMap.get(fieldId);
      nestedField?.entries.forEach((entry) => {
        if (formField.widget === "com.moreapps:detail:1") {
          const nestedSubform = getSubformVersion(formField, fieldProperties);
          result.set(entry.id, getRuleActionMap(nestedSubform.rules));
          result = new Map([
            ...result,
            ...generateRuleMap(submissionId, nestedSubform.fields, fieldsMap, fieldProperties, entry.id, fieldId),
          ]);
        } else if (formField.widget === "com.moreapps:pin:1") {
          const nestedPinforms = getPinFormVersions(formField, fieldProperties);
          const pinForm = nestedPinforms.find(({ uid }) => entry.meta.scope.target === uid);
          result.set(entry.id, getRuleActionMap(pinForm?.rules) ?? []);
          result = new Map([
            ...result,
            ...generateRuleMap(submissionId, pinForm?.fields ?? [], fieldsMap, fieldProperties, entry.id, fieldId),
          ]);
        }
      });
    });

  return result;
};

const getFormRuleContexts = (submissionId: string, fields: Field[], formVersion: FormVersion): FormRuleContext[] => {
  // Generates a map with all the rules for every form field
  const ruleActionMap = getRuleActionMap(formVersion.rules);

  // Generates a map with all the rules for every nested form field
  const fieldsMap = new Map<string, Field>(fields.map((field) => [field.id, field]));
  const rulesMap: Map<string, Map<string, FormRule[]>> = generateRuleMap(
    submissionId,
    formVersion.fields,
    fieldsMap,
    formVersion.fieldProperties,
  );

  // Generates a map with all the scoped with formFieldUid as key
  const rootScopedFields = getScopedFields(fields.filter((x) => x && !x.entryId));
  // Generates a map with all the scoped fields for every nested entry
  const nestedScopedFields = getNestedScopedFields(fields);

  return fields
    .filter((field) => field)
    ?.map((field) => {
      const formVersionRules = field.entryId ? rulesMap.get(field.entryId) : ruleActionMap;
      const rules = formVersionRules?.get(field.formFieldId) ?? [];
      return { field, rules };
    })
    .filter((x) => x.rules.length > 0)
    .map((fieldRule) => {
      const scopedFields = fieldRule.field.entryId ? nestedScopedFields.get(fieldRule.field.entryId) : rootScopedFields;
      return { ...fieldRule, scopedFields: scopedFields ?? new Map() };
    });
};

const getFieldActions = (fieldRules: FormRuleContext[], fields: Field[], username: string): FieldRuleResult[] =>
  fieldRules.reduce((acc: FieldRuleResult[], fieldRule) => {
    const actionResults = fieldRule.rules.flatMap((rule) =>
      getRuleActions(fieldRule.field, rule, fieldRule.scopedFields, fields, username),
    );

    // Only keep the last 'Set Visibility' action. We only want to evaluate the last action
    const visibilityAction = last(actionResults.filter((x) => x.type === "SET_VISIBILITY"));
    const results = [
      ...actionResults.filter((x) => x.type !== "SET_VISIBILITY"),
      ...(visibilityAction ? [visibilityAction] : []),
    ];

    if (results) {
      return [...acc, { uniqueFieldId: fieldRule.field.id, results }];
    }
    return acc;
  }, []);

const getFieldResult = (
  uniqueFieldId: UniqueFieldId,
  ruleResults: RuleResult[],
  fields: Field[],
): FieldResult | undefined => {
  const field = fields.find((x) => x.id === uniqueFieldId);
  const current = field?.evaluatedRules?.filter((x) => x?.type) ?? []; // FIXME we only need to filter because the old version used number[]
  if (deepEqual(current, ruleResults)) {
    // No updates to rules
    return undefined;
  }
  // Always use the last Set Value action result where the conditions are met.
  const value = getRuleValue(last(ruleResults.filter((x) => x.type === "SET_VALUE"))?.value, field);
  const isUpdated = value !== undefined && !deepEqual(value, field?.data);

  // Always use the last Set Visibility action result where the conditions are met.
  const hidden = !!ruleResults?.filter((i) => i.type === "SET_VISIBILITY").find((i) => !i.visible);
  return { uniqueFieldId, ...(isUpdated ? { value } : {}), hidden, ruleResults };
};

/**
 * The config in the form allows for values of type 'string' and 'boolean'.
 * This converts these naive values to something that can be set as the rawValue.
 *
 * This is probably not enough yet, we might want to expand on this, once users discover more ways to cause this scenario.
 *
 * @param input naive user input from platform
 * @param field target field to modify
 */
const getRuleValue = (
  input?: string | boolean,
  field?: Field,
):
  | string
  | number
  | boolean
  | undefined
  | null
  | { date: string; time: string }
  | { ibanNumber: string }
  | { value: number } => {
  if (input === undefined) {
    return input;
  }
  if (field?.type === "number" && typeof input === "string") {
    return getSafeNumber(input) ?? null;
  }
  if (field?.type === "currency" && typeof input === "string") {
    const number = getSafeNumber(input);
    return number ? { value: number } : null;
  }
  if (field?.type === "datetime" && typeof input === "string") {
    const dateTime = DateTime.fromFormat(input, "yyyy-MM-dd HH:mm");
    if (!dateTime.isValid) {
      return null; // Value can be total nonsense, don't bother setting it.
    }
    return {
      date: dateTime.toFormat("yyyy-MM-dd"),
      time: dateTime.toFormat("HH:mm"),
    };
  }
  if (field?.type === "date" && typeof input === "string") {
    const date = DateTime.fromFormat(input, "yyyy-MM-dd");
    if (!date.isValid) {
      return null; // Value can be total nonsense, don't bother setting it.
    }
    return date.toFormat("yyyy-MM-dd");
  }
  if (field?.widget === "iban" && typeof input === "string") {
    return { ibanNumber: input };
  }
  return input ?? null;
};

const getRuleActionMap = (rules?: FormRule[]): Map<string, FormRule[]> => {
  const actionMap = new Map<string, FormRule[]>();

  rules?.forEach((rule) => {
    rule.actions.forEach((action) => {
      const { fieldUid } = action;

      if (!actionMap.has(fieldUid)) {
        actionMap.set(fieldUid, []);
      }
      actionMap.get(fieldUid)?.push(rule);
    });
  });

  return actionMap;
};

const ROOT_ID = "root";
const getNestedScopedFields = (fields: Field[]): EntryFieldMap => {
  const fieldsById = new Map<string, Field>();
  const fieldsByEntryId = new Map<string, Field[]>();

  fields?.forEach((field) => {
    if (isNil(field)) {
      return;
    }
    // Map field id -> field
    fieldsById.set(field.id, field);

    // Map entryId -> Field[]
    const entryId = field.entryId ?? ROOT_ID;
    if (!fieldsByEntryId.has(entryId)) {
      fieldsByEntryId.set(entryId, []);
    }
    fieldsByEntryId.get(entryId)?.push(field);
  });

  return fields?.reduce((acc, field) => {
    if (isNil(field.entryId) || acc.has(field.entryId)) {
      return acc;
    }

    // Get fields for Subform entry with parent ID
    const parentFieldEntryId = field.parentId ? fieldsById.get(field.parentId)?.entryId : undefined;

    const ownFields = fieldsByEntryId.get(field.entryId) ?? [];
    const parentFields = fieldsByEntryId.get(parentFieldEntryId ?? ROOT_ID) ?? [];

    // Get fields for current entry
    const scopedFields = getScopedFields([...ownFields, ...parentFields]);
    acc.set(field.entryId, scopedFields);
    return acc;
  }, new Map<string, Map<string, Field>>());
};

const getScopedFields = (fields: Field[]): FieldMap => {
  const scopedFields = new Map<string, Field>();
  fields.forEach((field) => scopedFields.set(field.formFieldId, field));
  return scopedFields;
};
