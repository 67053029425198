import { FC } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import useSubmissionBadge from "../hooks/useSubmissionBadge";
import useAuth from "../hooks/useAuth";
import { SideMenu, SideMenuOption } from "../storybook/components/SideMenu/SideMenu";
import branding from "../utils/brandingUtil";

type NavSideBarProps = {
  className?: string;
};

const NavSideBar: FC<NavSideBarProps> = ({ className }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { drafts, tasks, sending, cutOffValue } = useSubmissionBadge();
  const { hasCustomerAccess } = useAuth();

  const selected = location.pathname.split("/")[1];
  const options: SideMenuOption[] = [
    {
      href: "folders",
      label: t("MENU_NEW_FORM"),
      icon: "DocumentIcon",
      disabled: !hasCustomerAccess,
    },
    {
      href: "tasks",
      label: t("MENU_TASKS"),
      icon: "CheckCircleIcon",
      disabled: !hasCustomerAccess,
      ...(tasks && {
        badge: { variant: "important", value: tasks, cutOffValue },
      }),
    },
    {
      href: "drafts",
      label: t("MENU_DRAFTS"),
      icon: "PencilAltIcon",
      disabled: !hasCustomerAccess,
      ...(drafts && {
        badge: { value: drafts, cutOffValue },
      }),
    },
    {
      href: "sent",
      label: t("MENU_SENT"),
      icon: "SendIcon",
      disabled: !hasCustomerAccess,
      ...(sending && {
        badge: { value: sending, cutOffValue },
      }),
    },
  ];

  return (
    <SideMenu
      className={className}
      title={branding.name}
      showTitle={!branding.isBranded()}
      selected={selected}
      logoSrc={branding.isBranded() ? "/assets/logo.png" : "/assets/logo.svg"}
      options={options}
      altOptions={[{ href: "settings", label: t("MENU_SETTINGS"), icon: "CogIcon", disabled: false }]}
    />
  );
};

export default NavSideBar;
