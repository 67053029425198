import { useTranslation } from "react-i18next";
import { toIsoDate } from "../../utils/dateUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { DateTimeInput } from "../../storybook/components/DateTimeInput/DateTimeInput";
import WidgetContainer from "../WidgetContainer";

export type WidgetDateProperties = {
  required: boolean;
  label_text: string;
  now_as_default: boolean;
};

const WidgetDate: Widget<WidgetDateProperties, WidgetResult<string>> = ({ fieldState, setFieldState, readOnly }) => {
  const { t } = useTranslation();

  return (
    <WidgetContainer fieldState={fieldState} name="DATE_FIELD">
      <DateTimeInput
        type="date"
        name={fieldState.uniqueFieldId}
        value={{ date: fieldState.value.rawValue }}
        errorMessage={fieldState.error}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        disabled={readOnly}
        onClear={() => setFieldState(undefined)}
        onChange={(value) => setFieldState(value.date)}
        showClearBtn={!!fieldState.value.rawValue && !readOnly}
        clearLabel={t("CLEAR")}
      />
    </WidgetContainer>
  );
};

WidgetDate.defaultValue = (properties, defaultMeta: any): WidgetResult<string> => ({
  type: "date",
  rawValue: properties.now_as_default ? toIsoDate(new Date()) : undefined,
  meta: {
    widget: "date",
    ...defaultMeta,
  },
});

WidgetDate.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetDate;
