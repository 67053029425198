import { FC, ReactElement } from "react";
import pMinDelay from "p-min-delay";
import SimplePullToRefresh from "react-simple-pull-to-refresh";
import { Spinner } from "../storybook/components/Spinner/Spinner";

type PullToRefreshProps = {
  onRefresh: () => Promise<unknown>;
  disabled?: boolean;
};

type PropsWithChildren<P = unknown> = P & { children: ReactElement };

/** The promise to reload folders usually resolves very quickly.
 *  Add artificial delay to avoid users refreshing multiple times because it 'feels' too fast.
 */
const MINIMUM_DELAY = 1000;

// Magic design numbers
const PULL_THRESHOLD = 100;
const MAX_PULL = 224;

const PullToRefresh: FC<PullToRefreshProps & PropsWithChildren> = ({ children, onRefresh, disabled }) => (
  <SimplePullToRefresh
    isPullable={!disabled}
    onRefresh={() => pMinDelay(onRefresh(), MINIMUM_DELAY)}
    className="bg-gray-50"
    pullDownThreshold={PULL_THRESHOLD}
    maxPullDownDistance={MAX_PULL}
    pullingContent={<div />} // Disable default pulling content
    refreshingContent={<Spinner className="mx-auto mt-8 text-gray-700" />}
  >
    {children}
  </SimplePullToRefresh>
);

export default PullToRefresh;
