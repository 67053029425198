import { FC } from "react";
import { RxDocument } from "rxdb";
import { isNil } from "lodash-es";
import useFormVersion from "../hooks/useFormVersion";
import { Submission } from "../types/Submission";
import SubmissionNotFoundPage from "./errorpages/SubmissionNotFoundPage";
import OfflinePage from "./errorpages/OfflinePage";
import useOnlineStatus from "../hooks/useOnlineStatus";
import LoadingPage from "./LoadingPage";
import useTheme from "../hooks/useTheme";
import SubmissionForm from "../components/SubmissionForm";
import useStatusBar from "../hooks/useStatusBar";
import { useSubmissionPageTitle } from "../hooks/useSubmissionPageTitle";
import useInitialFormState from "../hooks/useInitialFormState";
import useShowToastAndGoHomeOnInvalidFormVersion from "../hooks/useShowToastAndGoHomeOnInvalidFormVersion";
import useSubmissionComplete from "../hooks/useSubmissionComplete";

type SubmissionPageProps = {
  submission: RxDocument<Submission>;
};

const SubmissionPage: FC<SubmissionPageProps> = ({ submission }) => {
  const { id, formId, customerId, formVersionId } = submission;

  const { isOnline } = useOnlineStatus();
  const { formVersion, isLoadingFormVersion, errorFormVersion } = useFormVersion(formVersionId, customerId, formId);
  const isComplete = useSubmissionComplete(submission);
  const formState = useInitialFormState(id, formId, formVersion, isComplete);
  useSubmissionPageTitle(submission);
  const theme = useTheme(formVersion);
  useStatusBar(theme.color);
  useShowToastAndGoHomeOnInvalidFormVersion(errorFormVersion);

  if (isLoadingFormVersion || isNil(formState) || !isComplete) {
    return (
      <LoadingPage
        conditions={[
          { labelKey: "LOADING_ERROR_FORM_VERSION", value: isLoadingFormVersion },
          { labelKey: "LOADING_SUBMISSION_FORM_STATE", value: !formState },
          { labelKey: "LOADING_ERROR_INITIALIZE_FIELDS", value: !isComplete },
        ]}
      />
    );
  }

  if (isNil(formVersion)) {
    return isOnline ? <SubmissionNotFoundPage /> : <OfflinePage />;
  }

  return <SubmissionForm submission={submission} formVersion={formVersion} theme={theme} formState={formState} />;
};

export default SubmissionPage;
