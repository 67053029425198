import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import WidgetLabel, { WidgetLabelProperties } from "./WidgetLabel";

export type WidgetParagraphProperties = {
  label_text: never;
  paragraph: string;
};

const WidgetParagraph: Widget<WidgetParagraphProperties, WidgetResult<void>> = (props) => <WidgetLabel {...props} />;

WidgetParagraph.defaultValue = (_properties, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "paragraph",
    ...defaultMeta,
  },
});

WidgetParagraph.validate = (): undefined => undefined;

WidgetParagraph.mapProperties = (input: WidgetParagraphProperties): WidgetLabelProperties => ({
  ...input,
  label_text: input.paragraph,
});

export default WidgetParagraph;
