import { FC, useEffect, useRef } from "react";
import LoadingPage from "./LoadingPage";
import { useParams } from "react-router";
import { useLocalForm } from "../hooks/useLocalForm";
import useFormVersion from "../hooks/useFormVersion";
import { createNewFields, createNewSubmission } from "../utils/submissionUtil";
import { isNil } from "lodash-es";
import useDeviceInfo from "../hooks/useDeviceInfo";
import useAuth from "../hooks/useAuth";
import useUserId from "../hooks/useUserId";
import useDatabase from "../hooks/useDatabase";
import useNavigateAsync from "../hooks/useNavigateAsync";

const NewFormPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const form = useLocalForm(id);
  const { id: deviceId } = useDeviceInfo();
  const { customerId } = useAuth();
  const userId = useUserId();
  const { database: db } = useDatabase();
  const navigate = useNavigateAsync();
  const { formVersion } = useFormVersion(form?.publishedVersion.formVersion, customerId, form?.id);
  const isOpening = useRef<boolean>(false);

  useEffect(() => {
    if (isNil(formVersion) || isNil(form) || isNil(userId) || isNil(customerId) || isNil(db) || isOpening.current) {
      return;
    }

    const openForm = async (): Promise<void> => {
      isOpening.current = true;
      const { id, formId } = await createNewSubmission(form, customerId, db.submissions);

      // @ts-expect-error this is what it is called in the database
      await createNewFields(id, formVersion, formId, deviceId, userId, db.fields, db["remembered-fields"]);

      navigate(`/submissions/${id}`, { replace: true });
    };

    openForm();
  }, [customerId, db, deviceId, form, formVersion, navigate, userId]);

  return (
    <LoadingPage
      conditions={[
        { labelKey: "LOADING_ERROR_CREATE_SUBMISSION", value: isOpening.current },
        { labelKey: "LOADING_ERROR_FORM_VERSION", value: isNil(formVersion) },
        { labelKey: "LOADING_SUBMISSION_DATABASE_FIELD", value: isNil(db?.fields) },
        { labelKey: "LOADING_SUBMISSION_DATABASE_SUBMISSION", value: isNil(db?.submissions) },
      ]}
    />
  );
};

export default NewFormPage;
