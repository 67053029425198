import { FC, MouseEvent } from "react";
import classNames from "classnames";
import { Icon } from "../storybook/components/Icon/Icon";

type SelectionIconProps = {
  checked: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};

const SelectionIcon: FC<SelectionIconProps> = ({ checked, onClick }) => {
  const classes = classNames({
    "h-6 w-6 rounded-full border border-2 flex justify-center items-center": true,
    "hover:bg-gray-100": !checked,
    "bg-brand-500 border-brand-500 hover:bg-brand-600 hover:border-brand-600": checked,
  });
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="p-3" onClick={onClick}>
      <div className={classes}>{checked && <Icon name="CheckIcon" className="size-5" />}</div>
    </div>
  );
};

export default SelectionIcon;
