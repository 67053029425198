import { useTranslation } from "react-i18next";
import { CameraSource } from "@capacitor/camera";
import { useMemo, useState } from "react";
import { fabric } from "fabric";
import { isNil, startsWith } from "lodash-es";
import { Capacitor } from "@capacitor/core";
import axios from "axios";
import { FileResult, Widget } from "../../types/Widget";
import { UploadStatus, WidgetResult } from "../../types/Field";
import { validateUpload } from "../../utils/validationUtil";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { DropdownMenu } from "../../storybook/components/DropdownMenu/DropdownMenu";
import { Label } from "../../storybook/components/Label/Label";
import Img from "../Img";
import { Spinner } from "../../storybook/components/Spinner/Spinner";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { Sketchbook } from "../Sketchbook";
import DatasourceImageSelect from "./search/DatasourceImageSelect";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";
import useDatasourceImages from "../../hooks/useDatasourceImages";
import { API_URL } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { useMoreAppClient } from "../../context/MoreAppContext";
import uuidv4 from "../../utils/uuid";
import useFileHandler, { type UploadResult } from "../../hooks/useFileHandler";
import {
  backgroundUrlUpdateNeeded,
  enforceBoundaries,
  generateImage,
  updateBackgroundUrl,
} from "../../utils/drawingUtil";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { noopAsync } from "../../utils/noop";
import useCamera, { InsufficientPermissionError } from "../../hooks/useCamera";
import logger from "../../utils/logger";
import { getRemoteResourceUrl } from "../../utils/fileUtil";
import FileFeedback from "./file/FileFeedback";
import WidgetContainer from "../WidgetContainer";
import useStateSubmissionId from "../../state/useStateSubmissionId";
import FieldUploadStatus from "../FieldUploadStatus";
import useFieldUploadState from "../../hooks/useFieldUploadState";
import { useFocusId } from "../../hooks/useFocusId";

export type WidgetDrawingProperties = {
  required: boolean;
  label_text: string;
  background_image?: string;
};

export type DrawingData = {
  canvas?: any;
  dimensions?: {
    width: number;
    height: number;
  };
  imageUsed?: boolean;
};

export type DrawingResult = FileResult & DrawingData;

const WidgetDrawing: Widget<WidgetDrawingProperties, WidgetResult<DrawingResult>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();
  const submissionId = useStateSubmissionId();
  const [open, setOpen] = useState<boolean>(false);
  const [selectImageDrawer, setSelectImageDrawer] = useState(false);
  const images = useDatasourceImages(submissionId);
  const [drawing, setDrawing] = useState<string | undefined>(undefined);
  const [showPermissionsModal, setShowPermissionsModal] = useState<boolean>(false);
  const [backgroundUrl, setBackgroundUrl] = useState<string | undefined>(undefined);
  const { customerId } = useAuth();
  const client = useMoreAppClient();
  const { getFileUrl, storeFileUri } = useFileHandler();
  const { getPhoto } = useCamera();
  const [drawingCanvas, setDrawingCanvas] = useState<fabric.Canvas | undefined>(undefined);
  const { isUploading, uploadPercentage } = useFieldUploadState(fieldState);
  const onCloseFocusId = useFocusId(fieldState);

  const persistWithUploadStatus = (drawingResult?: DrawingResult, uploadStatus?: UploadStatus): void => {
    setFieldState(drawingResult, { uploadStatus });
  };

  useAsyncEffect(
    async () => {
      const { rawValue } = fieldState.value;
      if (isNil(rawValue)) {
        // No drawing, abort
        return;
      }
      const background = await getBackgroundImage();

      // ReadOnly with FileResult
      if (readOnly && isNil(rawValue.canvas)) {
        setDrawing(background);
        return;
      }

      // Imaged copied, but no canvas yet created
      if (isNil(rawValue.canvas) && !isNil(rawValue.imageUsed)) {
        setDrawing(background);
        setBackgroundUrl(background);
        return;
      }

      // When copied from another submission, we need to create a new drawing
      if (isNil(rawValue.imageUsed) && !readOnly) {
        await createNewDrawingFromCopy();
        return;
      }

      if (rawValue.imageUsed) {
        // check if we need to update the background url in the canvas. Get the remote path for it
        const fileUrl = await getFileUrl(rawValue, submissionId, true);
        const fileSrc = Capacitor.convertFileSrc(fileUrl!);
        if (!readOnly && backgroundUrlUpdateNeeded(fileSrc, rawValue)) {
          persistWithUploadStatus(updateBackgroundUrl(rawValue, fileSrc));
          return;
        }
      }
      const canvasWithUpdatedBackgroundUrl = updateBackgroundUrl(rawValue, background!);
      const previewImage = await generateImage(canvasWithUpdatedBackgroundUrl);
      setDrawing(previewImage);
      setBackgroundUrl(background);
      setDrawingCanvas(canvasWithUpdatedBackgroundUrl.canvas);
    },
    noopAsync,
    [fieldState.value.rawValue],
  );

  const getBackgroundImage = async (): Promise<string | undefined> => {
    if (isNil(fieldState.value.rawValue)) {
      return undefined;
    }
    const { rawValue } = fieldState.value;

    // Drawing only, no set background image, no uploaded image
    if (rawValue.imageUsed === false) {
      return undefined;
    }

    // get the image from the uploaded/set file
    const fileUrl = await getFileUrl(rawValue, submissionId);
    return Capacitor.convertFileSrc(fileUrl!);
  };

  const getDefaultImage = async (): Promise<string | undefined> => {
    const resourceUrl = getResourceUrl(fieldState.properties.background_image);
    if (isNil(resourceUrl)) {
      return undefined;
    }

    const { data } = await client!.get(resourceUrl, { responseType: "blob" });
    return URL.createObjectURL(data);
  };

  const clear = (): void => {
    setDrawing(undefined);
    setBackgroundUrl(undefined);
    setDrawingCanvas(undefined);
    persistWithUploadStatus(undefined, undefined);
    setOpen(false);
  };

  const save = async (canvas: fabric.Canvas | undefined): Promise<void> => {
    if (!canvas) {
      setOpen(false);
      return;
    }

    canvas.setZoom(1); // Render full frame
    enforceBoundaries(canvas);
    const canvasObj = canvas.toObject();
    const dimensions = {
      height: canvas.getHeight(),
      width: canvas.getWidth(),
    };

    setDrawing(canvas.toDataURL());

    if (startsWith(canvasObj?.backgroundImage?.src, "data:")) {
      // Don't persist base64 in RxDB
      canvasObj.backgroundImage.src = null;
      canvasObj.backgroundImage.crossOrigin = "anonymous"; // Ensure we allow CORS on loading the initial image
    }

    const updatedValue: DrawingResult = {
      ...(fieldState.value.rawValue as DrawingResult),
      canvas: canvasObj,
      dimensions,
    };
    setFieldState(updatedValue);
    setOpen(false);
  };

  const uploadBackgroundImage = async (format: string, dataUrl: string): Promise<UploadResult> => {
    const imageId = uuidv4();
    const name = `photo.${format}`;

    return storeFileUri(dataUrl, submissionId, imageId, name);
  };

  const createNewDrawingFromCopy = async (): Promise<void> => {
    if (isNil(fieldState.value.rawValue)) {
      return;
    }
    const fileUrl = await getFileUrl(fieldState.value.rawValue, submissionId);
    const image = Capacitor.convertFileSrc(fileUrl!);
    setBackgroundUrl(image);
    setDrawing(image);
    await setNewDrawing("png", image, true);
  };

  const createNewDrawing = async (): Promise<void> => {
    const image = await getDefaultImage();

    if (image === undefined) {
      setFieldState({ imageUsed: false });
      setOpen(true);
      return;
    }
    await setNewDrawing("png", image, true);
    setOpen(true);
  };

  const createDrawingFromPhoto = async (source: CameraSource): Promise<void> => {
    try {
      const photo = await getPhoto(source);
      await setNewDrawing(photo.format, photo.webPath!, true);
      setOpen(true);
    } catch (error: any) {
      if (error instanceof InsufficientPermissionError) {
        setShowPermissionsModal(true);
        return;
      }
      logger.error("Could not create new drawing from photo", error);
    }
  };

  const createDrawingFromDataSource = async (url: string): Promise<void> => {
    if (!url) {
      return;
    }
    const { data } = await axios.get(url, { responseType: "blob" });
    const dataUrl = URL.createObjectURL(data);
    await setNewDrawing("png", dataUrl, true);
    setOpen(true);
  };

  const setNewDrawing = async (format: string, imageUrl: string, imageUsed: boolean): Promise<void> => {
    const result = await uploadBackgroundImage(format, imageUrl);
    persistWithUploadStatus({ ...result.fileResult, imageUsed }, result.uploadStatus);
    setBackgroundUrl(imageUrl);
    setDrawing(imageUrl);
  };

  const getResourceUrl = (resourceUrl?: string): string | undefined =>
    resourceUrl ? `${API_URL}/customers/${customerId}/resources/${resourceUrl}/download/direct` : undefined;

  const optionalSelectImageButton = useMemo(
    () => (images.length > 0 ? [{ label: t("SELECT_PHOTO"), onClick: (): void => setSelectImageDrawer(true) }] : []),
    [images, t],
  );

  const menuBtn = (
    <IconAndTextButton
      block
      disabled={readOnly}
      label={!readOnly ? t("ADD_DRAWING") : t("NO_DRAWING_ADDED")}
      icon="PencilIcon"
    />
  );

  const dropdownMenu = (
    <DropdownMenu
      className="w-full"
      menuButton={() => menuBtn}
      items={[
        {
          label: t("ADD_BLANK_DRAWING"),
          onClick: createNewDrawing,
        },
        { label: t("CAPTURE_PHOTO"), onClick: (): Promise<void> => createDrawingFromPhoto(CameraSource.Camera) },
        { label: t("BROWSE_PHOTOS"), onClick: (): Promise<void> => createDrawingFromPhoto(CameraSource.Photos) },
        ...optionalSelectImageButton,
      ]}
    />
  );

  const emptyBtn = !readOnly ? dropdownMenu : menuBtn;

  return (
    <WidgetContainer fieldState={fieldState} name="DRAWING_FIELD">
      <Label
        id={fieldState.uniqueFieldId}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        showClearBtn={!isNil(fieldState.value.rawValue) && !readOnly && !isUploading}
        onClear={clear}
        clearLabel={t("CLEAR")}
      />
      {fieldState.value.rawValue ? (
        <div className="relative">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="relative z-10 cursor-pointer rounded-lg border-2 bg-white"
            onClick={() => !readOnly && !isUploading && setOpen(true)}
          >
            {!isUploading && <Img src={drawing} alt={t("DRAWING")} className="z-10 mx-auto h-40" />}
            {isUploading && (
              <div className="relative flex h-40 items-center justify-center">
                <Spinner className="size-8" />
              </div>
            )}
            {!readOnly && !isUploading && (
              <div className="absolute right-4 top-4">
                <IconButton id={onCloseFocusId} aria-label={t("EDIT_DRAWING")} icon="PencilIcon" />
              </div>
            )}
          </div>

          <FieldUploadStatus percentage={uploadPercentage} status={fieldState.value.meta.uploadStatus} />
        </div>
      ) : (
        emptyBtn
      )}
      <FileFeedback fieldState={fieldState} />
      <Sketchbook
        onCloseFocusId={onCloseFocusId}
        open={open}
        setOpen={setOpen}
        save={save}
        clear={clear}
        initialCanvas={drawingCanvas}
        backgroundUrl={backgroundUrl}
        initialSize={fieldState.value.rawValue?.dimensions}
      />
      <DatasourceImageSelect
        open={selectImageDrawer}
        setOpen={setSelectImageDrawer}
        images={images}
        onSelect={createDrawingFromDataSource}
      />
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setShowPermissionsModal(false)} />
      )}
    </WidgetContainer>
  );
};

WidgetDrawing.defaultValue = (_properties, defaultMeta): WidgetResult<DrawingResult> => ({
  type: "file",
  meta: {
    widget: "drawing",
    ...defaultMeta,
  },
});

WidgetDrawing.validate = (val, properties, t, meta): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  if (val?.id) {
    // Check upload when background image is attached
    return validateUpload(val, meta.uploadStatus, t);
  }
  return undefined;
};

WidgetDrawing.onUploadComplete = async (value: DrawingResult | undefined): Promise<DrawingResult> => {
  if (isNil(value?.remoteId)) {
    throw new Error("Remote ID not found");
  }

  if (isNil(value?.canvas?.backgroundImage)) {
    return value;
  }

  return updateBackgroundUrl(value, getRemoteResourceUrl(value.remoteId));
};

export default WidgetDrawing;
