import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

export type IndicatorProps = {
  className?: string;
  size?: "xs" | "sm" | "md" | "lg";
  color?: string;
  ariaLabel?: string;
};

export const Indicator: FC<PropsWithChildren<IndicatorProps>> = ({ className, size = "sm", color, ariaLabel }) => {
  const classes = classNames(
    "inline-block shrink-0 rounded-full",
    size === "lg" && "size-4",
    size === "md" && "size-3",
    size === "sm" && "size-2",
    size === "xs" && "size-1",
    !color && "bg-red-600",
    className,
  );

  return (
    <div role="status" aria-label={ariaLabel} className={classes} style={color ? { backgroundColor: color } : {}} />
  );
};
