import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import { Text } from "../../storybook/components/Text/Text";
import WidgetContainer from "../WidgetContainer";

export type WidgetLabelProperties = {
  label_text: string;
};

const WidgetLabel: Widget<WidgetLabelProperties, WidgetResult<void>> = ({ fieldState }) => (
  <WidgetContainer fieldState={fieldState} name="LABEL_FIELD" className="pb-4">
    <Text>{fieldState.properties.label_text}</Text>
  </WidgetContainer>
);

WidgetLabel.defaultValue = (_properties, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "label",
    ...defaultMeta,
  },
});

WidgetLabel.validate = (): undefined => undefined;

export default WidgetLabel;
