import { FC, PropsWithChildren } from "react";

export type FreestyleComponentProps = {
  kind: "freestyle";
  className?: string;
} & PropsWithChildren;

const FreestyleComponent: FC<FreestyleComponentProps> = ({ children, className }) => (
  <div className={className}>{children}</div>
);

export default FreestyleComponent;
