import { MapContainer, TileLayer } from "react-leaflet";
import { FC, useEffect, useRef, useState } from "react";
import { LatLng, LatLngLiteral, Map as ContainerMap } from "leaflet";
import { useTranslation } from "react-i18next";
import { useResizeObserver } from "usehooks-ts";
import ClickableMarker from "./ClickableMarker";
import useLocationConfiguration, { MapStyle, TileConfiguration } from "../../../hooks/useLocationConfiguration";
import useDrawer from "../../../hooks/useDrawer";
import SearchDrawer from "./SearchDrawer";
import { getLocation, getReverseLocation, locationResultToLatLng } from "../../../utils/locationUtil";
import LocationMarker from "./LocationMarker";
import InsufficientPermissionsModal from "../../InsufficientPermissionsModal";
import logger from "../../../utils/logger";
import usePermissions from "../../../hooks/usePermissions";
import { LocationResult } from "../../../types/Widget";
import { Spinner } from "../../../storybook/components/Spinner/Spinner";
import { SearchField } from "../../../storybook/components/SearchField/SearchField";
import { IconButton } from "../../../storybook/components/IconButton/IconButton";
import { noop } from "../../../utils/noop";

type LocationProps = {
  className?: string;
  mapPosition?: LatLngLiteral;
  userPosition?: LatLngLiteral;
  markerPosition?: LocationResult;
  setMarkerPosition?: (val: LocationResult) => void;
  onEdit?: () => void;
  centerOnMarker?: boolean;
};

const FALLBACK_POSITION: LatLngLiteral = { lat: 51.924419, lng: 4.477733 };
const ZOOM_LEVEL = 18;

const LeafletMap: FC<LocationProps> = ({
  className,
  mapPosition = FALLBACK_POSITION,
  userPosition,
  markerPosition,
  setMarkerPosition,
  onEdit,
  centerOnMarker = false,
}) => {
  const { t } = useTranslation();
  const [mapStyle, setMapStyle] = useState<MapStyle>("STREET");
  const { data: locationConfiguration, isFetching } = useLocationConfiguration();
  const [tileConfiguration, setTileConfiguration] = useState<TileConfiguration>();
  const mapRef = useRef<ContainerMap>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const size = useResizeObserver({ ref: containerRef });
  const [search, setSearch] = useDrawer("search-leaflet");
  const [query, setQuery] = useState("");
  const { checkLocationPermissions } = usePermissions();
  const [showPermissionsModal, setPermissionsModal] = useState(false);

  useEffect(() => {
    mapRef.current?.invalidateSize();
  }, [size?.height]);

  useEffect(() => {
    if (markerPosition) {
      setQuery(markerPosition.formattedValue ?? "");
    }
  }, [markerPosition]);

  useEffect(() => {
    if (locationConfiguration === undefined) {
      return;
    }

    setTileConfiguration(
      // Default to street
      locationConfiguration.tiles[mapStyle] || locationConfiguration.tiles.STREET,
    );
  }, [locationConfiguration, mapStyle]);

  useEffect(() => {
    if (tileConfiguration && mapRef.current) {
      mapRef.current.setMaxZoom(tileConfiguration.maxZoom);
    }
  }, [tileConfiguration]);

  if (isFetching || !locationConfiguration || !tileConfiguration) {
    return <Spinner className="mx-auto mt-4" />;
  }

  const onClick = (event: any): void => {
    event.stopPropagation();
    setSearch(true);
  };

  const setPosition = async (val: LatLngLiteral): Promise<void> => {
    const reverse = await getReverseLocation(val);
    setMarkerPosition && setMarkerPosition(reverse);
  };

  const moveToLocation = async (): Promise<void> => {
    try {
      const permissions = await checkLocationPermissions();
      if (permissions.coarseLocation === "denied" && permissions.location === "denied") {
        setPermissionsModal(true);
        return;
      }
      const position = await getLocation();
      const latLng = new LatLng(position.latitude, position.longitude);
      if (mapRef.current) {
        mapRef.current.flyTo(latLng, ZOOM_LEVEL, { duration: 0.5 });
      }
    } catch (e) {
      logger.error("Could not set/move to location", e);
    }
  };

  const toggleMapStyle = (): void => {
    setMapStyle(mapStyle === "STREET" ? "SATELLITE" : "STREET");
  };

  return (
    <>
      <div className="relative h-full" ref={containerRef}>
        <MapContainer
          className={className}
          style={{ height: "100%", zIndex: 0 }}
          ref={mapRef}
          center={
            centerOnMarker && markerPosition?.coordinates
              ? locationResultToLatLng(markerPosition)
              : mapPosition || userPosition
          }
          zoom={centerOnMarker ? 17 : 5}
          doubleClickZoom
          inertia
          dragging
          scrollWheelZoom
          zoomControl={false}
        >
          <TileLayer
            attribution={tileConfiguration.attribution}
            url={tileConfiguration.url}
            maxZoom={tileConfiguration.maxZoom}
          />
          <ClickableMarker position={locationResultToLatLng(markerPosition)} setPosition={setPosition} />
          <LocationMarker />
        </MapContainer>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div onClick={onClick} className="absolute top-4 z-500 w-full px-4">
          <SearchField
            className="rounded-full shadow"
            placeholder={t("SEARCH_PLACEHOLDER")}
            onChange={noop}
            value={query}
          />
        </div>
        {locationConfiguration.tiles.SATELLITE && (
          <div className="absolute right-5 top-20 z-500">
            <IconButton
              aria-label={mapStyle === "STREET" ? t("SWITCH_TO_SATELLITE") : t("SWITCH_TO_STREET")}
              className="shadow"
              key="changeMapStyle"
              icon={mapStyle === "STREET" ? "GlobeIcon" : "MapIcon"}
              onClick={() => toggleMapStyle()}
              data-testid="toggle-map-style-btn"
            />
          </div>
        )}
        {markerPosition && (
          <div className="absolute bottom-20 right-5 z-500">
            <IconButton
              aria-label={t("EDIT_LOCATION")}
              className="shadow"
              key="edit"
              icon="PencilIcon"
              onClick={() => onEdit && onEdit()}
            />
          </div>
        )}
        <div className="absolute bottom-6 right-5 z-500">
          <IconButton
            aria-label={t("MOVE_TO_CURRENT_LOCATION")}
            className="shadow"
            key="currentLocation"
            icon="CurrentLocationIcon"
            onClick={() => moveToLocation()}
          />
        </div>
      </div>

      <SearchDrawer
        open={search}
        onClose={() => setSearch(false)}
        onSelect={(val) => {
          setMarkerPosition && setMarkerPosition(val);
        }}
        query={query}
        setQuery={setQuery}
        mapRef={mapRef}
      />
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setPermissionsModal(false)} />
      )}
    </>
  );
};

export default LeafletMap;
