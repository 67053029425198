import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SearchResult } from "minisearch";
import { isEqual } from "lodash-es";
import DataSourceMiniSearchWorker from "../workers/dataSourceMiniSearchWorker?worker";
import { DatasourceOptions, DataSourceResponse } from "../workers/dataSourceMiniSearchWorker";
import { postMessage } from "../utils/workerUtil";
import { noop } from "../utils/noop";
import useWorker from "./useWorker";
import { MiniSearchResult, WithScore } from "./useMiniSearch";

const useMiniSearchWorker = <T>(
  query: string,
  enabled: boolean,
  options: Options,
  getId: (i: T) => string,
  isExactQuery: boolean,
  list: T[] = [],
  filters: string[] = [],
): MiniSearchResult<T> => {
  const [isInitializing, setInitializing] = useState(true);
  const [isError, setError] = useState(false);
  const [searchList, setSearchList] = useState<T[]>();
  const [searchFilters, setSearchFilters] = useState<string[]>();
  const [queryResult, setQueryResult] = useState<WithScore<T>[]>([]);
  const worker = useWorker(options.type, () => createWorker(options));

  useEffect(() => {
    if (!options || !enabled || !searchList || searchList.length === 0 || !worker || !isInitializing) {
      return;
    }

    const mappedList = searchList.reduce((acc, curr) => acc.set(getId(curr), curr), new Map<string, T>());

    const listener = (e: MessageEvent): void => {
      const response: DataSourceResponse = JSON.parse(e.data);
      switch (response.type) {
        case "initialized":
          setInitializing(false);
          break;
        case "added":
          break;
        case "searchResult":
          setResult(response.result, mappedList, setQueryResult);
          break;
        case "closed":
          worker.removeEventListener("message", listener);
          break;
        case "error": {
          setInitializing(false);
          setError(true);
        }
      }
    };
    worker.addEventListener("message", listener);

    postMessage(worker, { type: "initialize", options });
  }, [options, enabled, worker, isInitializing, searchList, getId]);

  // Add data to MiniSearch after initialization is complete
  useEffect(() => {
    if (!isInitializing && worker && searchList) {
      postMessage(worker, { type: "add", list: searchList });
    }
  }, [isInitializing, searchList, worker]);

  // Closing/Cleaning up MiniSearch on destructure of hook
  useEffect(() => {
    if (isInitializing || !worker) {
      return noop;
    }

    return (): void => postMessage(worker, { type: "close" });
  }, [isInitializing, worker]);

  // Show no results when hook has been disabled
  useEffect(() => {
    if (!enabled) {
      setQueryResult([]);
    }
  }, [enabled]);

  // Needed for unnecessary effect triggers, only change list and filters when the actual data itself changes
  useEffect(() => setSearchList((prev) => (isEqual(prev, list) ? prev : list)), [list]);
  useEffect(() => setSearchFilters((prev) => (isEqual(prev, filters) ? prev : filters)), [filters]);

  // Search whenever query changes
  useEffect(() => {
    if (!enabled || isInitializing || !worker || !searchList || !searchFilters) {
      return;
    }

    if (!query && options.matchAllOnEmptyQuery && searchFilters.length === 0) {
      setQueryResult(searchList.map((i) => ({ item: i })));
      return;
    }

    postMessage(worker, { type: "search", query, filters: searchFilters, isExactQuery });
  }, [isInitializing, searchFilters, query, enabled, options, worker, searchList, isExactQuery]);

  return {
    result: queryResult,
    isInitializing,
    isError,
  };
};

// Create data specific worker based on options since field extraction functions differ between data types

const createWorker = (options: Options): Worker => {
  switch (options.type) {
    case "datasource":
      return new DataSourceMiniSearchWorker();
  }
};

const setResult = <T>(
  searchResults: SearchResult[],
  mappedList: Map<string, T>,
  setMappedResults: Dispatch<SetStateAction<WithScore<T>[]>>,
): void => {
  const mappedResult = searchResults.map((i) => ({
    item: mappedList.get(i.id)!,
    score: i.score,
  }));
  setMappedResults(mappedResult);
};

type Options = DatasourceOptions;

export default useMiniSearchWorker;
