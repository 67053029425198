import { FC, useCallback, useEffect, useRef } from "react";
import { domMax, LazyMotion, m, useMotionValue, useTransform } from "framer-motion";
import { useNavigate } from "react-router";
import useToasts from "../hooks/useToasts";
import { Toast } from "../storybook/components/Toast/Toast";

const DURATION = 5000;
const SWIPE_RANGE = 125;

const Toasts: FC = () => {
  const { toast, hideToast } = useToasts();
  const navigate = useNavigate();
  const timer = useRef<number>();
  const xAxis = useMotionValue(0);
  const opacity = useTransform(xAxis, [-SWIPE_RANGE, 0, SWIPE_RANGE], [0, 1, 0]);

  useEffect(
    () =>
      xAxis.on("change", (latest) => {
        if (latest > SWIPE_RANGE || latest < -SWIPE_RANGE) {
          hideToast();
        }
      }),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!toast) {
      return;
    }
    if (timer.current) {
      window.clearTimeout(timer.current);
      timer.current = undefined;
    }
    timer.current = window.setTimeout(hideToast, DURATION);
  }, [hideToast, toast]);

  const withNavigate = useCallback(
    (event: any) => {
      toast?.button?.onClick(event);
      if (toast?.button?.navigateTo) {
        navigate(toast.button.navigateTo);
      }
    },
    [toast, navigate],
  );

  return (
    <LazyMotion features={domMax}>
      <m.div drag="x" style={{ x: xAxis, opacity }} dragSnapToOrigin className="absolute bottom-20 z-30 w-full px-4">
        <Toast
          className="mx-auto max-w-xl"
          message={toast?.message || ""}
          {...toast}
          button={toast?.button ? { ...toast?.button, onClick: withNavigate } : undefined}
          show={toast !== undefined}
        />
      </m.div>
    </LazyMotion>
  );
};

export default Toasts;
