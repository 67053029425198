import { FC, PropsWithChildren, useContext, useMemo, useRef } from "react";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import { isNil, round } from "lodash-es";
import { useResizeObserver } from "usehooks-ts";
import useOnlineStatus from "../hooks/useOnlineStatus";
import { Theme } from "../storybook/themes";
import { Submission } from "../types/Submission";
import SubmissionContentHeader from "./SubmissionContentHeader";
import { Banner, BannerProps } from "../storybook/components/Banner/Banner";
import { IconAndTextButton } from "../storybook/components/IconAndTextButton/IconAndTextButton";
import { DrawerContext } from "../context/DrawerContext";
import { FormEngineRunStats } from "../utils/FormEngine";
import useDevMode from "../hooks/useDevMode";
import useStateLastRunStats from "../state/useStateLastRunStats";

export type PreviewMode = "default" | "compact";

type FormContentWrapperProps = {
  theme: Theme;
  submission: Submission;
  header: JSX.Element;
  onSubmit?: () => Promise<void>;
  preview?: PreviewMode;
  isSubmitting: boolean;
};

const FormEnginePerformanceStats: FC = () => {
  const lastRunStats = useStateLastRunStats();

  if (!lastRunStats) {
    return null;
  }

  return <span className="mx-auto table text-sm text-white">{getRunStatsLine(lastRunStats)}</span>;
};

const FormContentWrapper: FC<PropsWithChildren & FormContentWrapperProps> = ({
  children,
  theme,
  preview,
  isSubmitting,
  submission,
  header,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const headerRef = useRef<HTMLDivElement>(null);
  const { height } = useResizeObserver({ ref: headerRef });
  const { activeDrawer } = useContext(DrawerContext);
  const [devMode] = useDevMode();

  const { isOnline } = useOnlineStatus();
  const bannerMessage = useMemo<BannerProps | undefined>(() => {
    if (preview === "default") {
      return {
        label: t("SUBMISSION_PREVIEW_DISCLAIMER"),
        icon: "EyeIcon",
      };
    }
    if (!isOnline) {
      return {
        label: t("INTERNET_DISCONNECTED"),
        icon: "StatusOfflineIcon",
      };
    }
    return undefined;
  }, [isOnline, preview, t]);

  const pageHeader = (
    <>
      <div ref={headerRef} className="sticky top-0 z-40 pt-safe" style={{ backgroundColor: theme.color }}>
        <div className="mx-auto max-w-xl">
          {header}
          {devMode && <FormEnginePerformanceStats />}
        </div>
      </div>
      {bannerMessage && (
        <div className="sticky z-30" style={{ top: `${height}px` }}>
          <Banner
            type="neutral"
            className="mx-auto h-8 max-w-xl"
            label={bannerMessage.label}
            icon={bannerMessage.icon}
          />
        </div>
      )}
    </>
  );
  const bodyClasses = classNames(
    "mx-auto flex w-full max-w-xl grow flex-col gap-y-6 rounded-t-3xl bg-white p-4 pt-6 mobile:flex-none mobile:rounded-b-3xl mobile:border-2 mobile:border-gray-100 mobile:p-7",
    Capacitor.isNativePlatform() && "after:pb-safe",
    !bannerMessage && "-mt-36",
  );

  const roundedBannerCorners = (
    <div className="z-30 mx-auto -mt-36 flex h-8 w-full max-w-xl justify-between">
      <div
        className="size-6"
        style={{
          backgroundColor: theme.color,
          WebkitMaskImage: "radial-gradient(circle at bottom right, transparent 0, transparent 24px, black 24px)",
          maskImage: "radial-gradient(circle at bottom right, transparent 0, transparent 24px, black 24px)",
        }}
      />
      <div
        className="size-6"
        style={{
          backgroundColor: theme.color,
          WebkitMaskImage: "radial-gradient(circle at bottom left, transparent 0, transparent 24px, black 24px)",
          maskImage: "radial-gradient(circle at bottom left, transparent 0, transparent 24px, black 24xpx)",
        }}
      />
    </div>
  );

  const sendButtonLabel = submission?.submittedAt ? t("SENT_FORM") : t("SEND_FORM");

  return (
    <main>
      <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
        <div
          // eslint-disable-next-line tailwindcss/no-contradicting-classname
          className={classNames(
            "h-screen h-screen-dynamic", // h-screen-dynamic is used when the dvh unit is supported, otherwise it's h-screen is used
            "flex w-full flex-col overscroll-none bg-gray-100",
            {
              "overflow-y-auto": !activeDrawer,
              "overflow-y-hidden": activeDrawer,
            },
          )}
        >
          {pageHeader}
          <div
            className={classNames("flex grow flex-col items-center", !Capacitor.isNativePlatform() && "mobile:pb-2")}
          >
            <div
              className={classNames("h-36 w-full", bannerMessage && "-mt-8")}
              style={{ backgroundColor: theme.color }}
            >
              {bannerMessage && <div className="mx-auto h-full max-w-xl bg-gray-200" />}
            </div>
            {bannerMessage && roundedBannerCorners}
            <div className={bodyClasses}>
              <SubmissionContentHeader submission={submission} />
              {children}
              {onSubmit && !preview && (
                <>
                  <div className="grow" />
                  <IconAndTextButton
                    className="mt-12"
                    block
                    variant="primary"
                    disabled={isSubmitting || !isNil(submission.submittedAt)}
                    label={sendButtonLabel}
                    icon="SendIcon"
                    loading={isSubmitting}
                    onClick={() => {
                      onSubmit().catch((err) => {
                        throw new Error("Could not submit submission", { cause: err });
                      });
                    }}
                  />
                </>
              )}
              {onSubmit && preview && (
                <>
                  <div className="grow" />
                  <IconAndTextButton
                    className="mt-12"
                    block
                    variant="primary"
                    disabled={isSubmitting}
                    label={t("VALIDATION_BUTTON")}
                    icon="CheckCircleIcon"
                    loading={isSubmitting}
                    onClick={onSubmit}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </main>
  );
};

const getRunStatsLine = (stats: FormEngineRunStats): string =>
  `${round(stats.userMutations)} + ${round(stats.autoMutations.total)} (${round(stats.autoMutations.rules)} + ${round(stats.autoMutations.calc)} + ${round(stats.autoMutations.timeDiff)}) + ${round(stats.descriptions)} + ${round(stats.validations)} = ${round(stats.total)}ms`;

export default FormContentWrapper;
