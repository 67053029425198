import { ChangeEvent, FC, MutableRefObject } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Icon } from "../Icon/Icon";

export type SearchFieldProps = {
  className?: string;
  focusableRef?: MutableRefObject<HTMLInputElement>;
  onChange: (newVal: string) => void;
  placeholder: string;
  value?: string;
};

export const SearchField: FC<SearchFieldProps> = ({ focusableRef, onChange, value = "", placeholder, className }) => {
  const { t } = useTranslation();
  const classes = classNames(
    "h-12 w-full rounded-full border-none bg-gray-100 px-11 outline-none pointer:group-hover:bg-brand-100",
    "ring-inset focus:bg-white focus:ring focus:group-hover:bg-white",
    "placeholder:text-gray-400 focus:placeholder:text-transparent focus:hover:placeholder:text-transparent pointer:hover:placeholder:text-brand-400",
    value ? "search-cancel-button text-gray-700" : "focus-within:text-brand-400 pointer:hover:text-brand-400",
  );

  return (
    <div className={classNames("group relative", className)}>
      <div className="pointer-events-none absolute inset-y-0 ml-4 flex items-center">
        <Icon name="SearchIcon" className="size-5 text-gray-400" />
      </div>
      <input
        ref={focusableRef}
        type="search"
        placeholder={placeholder}
        value={value}
        className={classes}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      />
      {value && (
        <div className="absolute inset-y-0 right-0 mr-2 flex items-center">
          <button
            aria-label={t("CLEAR")}
            className="rounded p-1 text-brand-500 outline-none hover:text-brand-600 focus-visible:ring"
            onClick={() => {
              onChange("");
              focusableRef?.current.focus();
            }}
          >
            <Icon name="XCircleIcon" />
          </button>
        </div>
      )}
    </div>
  );
};
