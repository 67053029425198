import React, { FC, useMemo, useState } from "react";
import { t } from "i18next";
import { isNil } from "lodash-es";
import { useBeforeunload } from "react-beforeunload";
import { RxCollection } from "rxdb";
import { Capacitor } from "@capacitor/core";
import { useNavigate } from "react-router";
import FormContentWrapper, { type PreviewMode } from "./FormContentWrapper";
import Form from "./Form";
import { updateSubmissionMeta } from "../utils/submissionUtil";
import { FormVersion } from "../types/FormVersion";
import { Field, fieldToRemote } from "../types/Field";
import { submissionToRemote } from "../types/Submission";
import logger from "../utils/logger";
import useFileHandler from "../hooks/useFileHandler";
import useDeviceInfo from "../hooks/useDeviceInfo";
import { SubmissionDocument } from "../utils/databaseUtil";
import { Theme } from "../storybook/themes";
import useToasts from "../hooks/useToasts";
import SubmissionDeleteModal from "./SubmissionDeleteModal";
import { IconAndTextButton } from "../storybook/components/IconAndTextButton/IconAndTextButton";
import branding from "../utils/brandingUtil";
import { useSubmissionStoreInitializer } from "../state/useSubmissionStoreInitializer";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import useSubmissionSend from "../hooks/useSubmissionSend";
import useInitialFormState from "../hooks/useInitialFormState";
import useActionGetFirstVisibleInvalidFieldId from "../state/useActionGetFirstVisibleInvalidFieldId";
import useActionSetFocussedField from "../state/useActionSetFocussedField";
import useActionValidateForm from "../state/useActionValidateForm";

type DirectUrlFormProps = {
  formVersion: FormVersion;
  submission: SubmissionDocument;
  theme: Theme;
  fieldCollection: RxCollection<Field>;
  isLoggedIn: boolean;
  preview?: PreviewMode;
};
const DirectUrlForm: FC<DirectUrlFormProps> = ({
  formVersion,
  submission,
  theme,
  fieldCollection,
  isLoggedIn,
  preview,
}) => {
  const { id, formId } = submission;
  const saveMode = formVersion?.settings.saveMode;

  const { removeLocalFiles } = useFileHandler();
  const device = useDeviceInfo();
  const { showToast } = useToasts();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const formState = useInitialFormState(id, formId, formVersion);
  const { initialized } = useSubmissionStoreInitializer(id, formId, formState, formVersion);
  const getFirstVisibleInvalidFieldId = useActionGetFirstVisibleInvalidFieldId();
  const setFocussedField = useActionSetFocussedField();
  const validateForm = useActionValidateForm();
  const { sendSubmission } = useSubmissionSend();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useBeforeunload(async (event) => {
    if (preview) {
      await submission.remove();
      return;
    }
    if (submission.status !== "final") {
      event.preventDefault();
    }
  });

  const handleSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      setFocussedField(undefined); // reset focus
      validateForm(undefined, { treatPendingUploadsAsInvalid: true });
      const firstVisibleInvalidFieldId = getFirstVisibleInvalidFieldId(undefined);

      if (!isNil(firstVisibleInvalidFieldId)) {
        setTimeout(() => setFocussedField({ uniqueFieldId: firstVisibleInvalidFieldId })); // ensure reset is done first
        return;
      }

      // Don't allow preview submissions to be sent
      if (preview) {
        showToast({ message: t("VALIDATION_SUCCEEDED_TOAST"), icon: "CheckIcon" });
        return;
      }

      // Update meta for final submission
      const fields = await fieldCollection.find().where("submissionId").eq(submission.id).exec();
      const finalFields = await Promise.all(fields.map(async (field) => field.incrementalPatch({ status: "final" })));
      const metaSubmission = await updateSubmissionMeta(submission, device);
      // Try to send to API
      await sendSubmission({
        fields: finalFields.map(fieldToRemote),
        ...submissionToRemote(metaSubmission),
        status: "final",
      });

      // Finalize and cleanup
      await metaSubmission.incrementalPatch({ status: "final" });
      await removeLocalFiles(submission.id);
    } catch (e) {
      logger.error("Couldn't submit submission", e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const showExitButton = Capacitor.isNativePlatform() || isLoggedIn;
  const showLogoHeader = !isLoggedIn && !branding.isBranded() && preview !== "compact";

  const header = useMemo(
    () => (
      <div className="grid h-14 grid-cols-3 items-center">
        {showExitButton && (
          <IconAndTextButton
            icon="ChevronLeftIcon"
            variant="transparentWhite"
            size="md"
            onClick={() => setShowDeleteModal(true)}
            label={t("BACK")}
            className="justify-self-start"
          />
        )}
        {showLogoHeader && (
          <a className="col-start-2 justify-self-center" href="https://moreapp.com" rel="noreferrer" target="_blank">
            <img src="/assets/logo-inverted.svg" alt={t("LOGO_ALT")} />
          </a>
        )}
      </div>
    ),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!initialized) {
    return <Spinner className="mx-auto my-8" />;
  }

  return (
    <>
      <SubmissionDeleteModal
        showModal={showDeleteModal}
        onConfirm={async () => {
          await submission.remove();
          await removeLocalFiles(submission.id);
          setShowDeleteModal(false);
          navigate("/");
        }}
        onModalClose={async () => setShowDeleteModal(false)}
      />
      <FormContentWrapper
        submission={submission}
        preview={preview}
        theme={theme}
        header={header}
        onSubmit={saveMode !== "NO_SAVE" ? handleSubmit : undefined}
        isSubmitting={isSubmitting}
      >
        <Form formVersion={formVersion} submissionId={submission.id} />
      </FormContentWrapper>
    </>
  );
};
export default DirectUrlForm;
