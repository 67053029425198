import { FC } from "react";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import { Modal } from "../storybook/components/Modal/Modal";
import { CorruptionState } from "../context/DatabaseContext";
import { noop } from "../utils/noop";

type CorruptDatabaseModalProps = {
  state?: CorruptionState;
};

const CorruptDatabaseModal: FC<CorruptDatabaseModalProps> = ({ state }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("DATABASE_CORRUPT_TITLE")}
      content={{
        kind: "message",
        message: t(state?.messageLabel ?? ""),
      }}
      open={!isNil(state)}
      onClose={noop}
      buttons={
        !isNil(state?.action)
          ? [
              {
                label: t(state?.actionLabel ?? ""),
                onClick: state?.action,
                variant: "destructive",
              },
            ]
          : []
      }
    />
  );
};

export default CorruptDatabaseModal;
